  
  <div class="global-container">
    <div class="input-container">
      <div class="input-content">
        <div class="header">
          <div class="input-heading m-2 mt-0"></div>
          <div class="settings-ico">
            <mat-icon class="setting-icon">settings</mat-icon>
            <div class="icon-btn-change" (click)="toggleLanguage()">
              <ng-container *ngIf="isEnglish">
                  <img class="btn-size" src="assets/images/EN.svg" alt="English" />
              </ng-container>
              <ng-container *ngIf="!isEnglish">
                  <img class="btn-size" src="assets/images/DE.svg" alt="German" />
              </ng-container>
          </div>
        </div>
        </div>
  
        <form [formGroup]="form">
          <div class="drop pt-2">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Process Selection</mat-label>
              <mat-select formControlName="process" id="process_dropdown" name="process_dropdown"
                (selectionChange)="onSelectProcess($event)">
                <mat-option *ngFor="let option of processOptions" [value]="option.process_id" [id]="option.process_id"
                  [disabled]="!option.is_available">
                  {{option.process_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div class="input-heading m-2 mt-0">Evidences:</div>
          <div class="file-details-container">
            <ng-container *ngFor="let file of fileDetails; let i = index">
              <div class="file-item">
                <div class="file-icon">
                  <img [src]="getFileIconPath(file)" alt="File icon" width="50" height="50">
                </div>
                <div class="file-name" [title]="file.file_name">{{file.file_name}}</div>
              </div>
            </ng-container>
          </div>
        </form>
        <div class="btn-group">
          <button mat-raised-button class="upload-btn" id="Upload_File_Requirements" (click)="openPopup()">
            <img class="upload-img" src="assets/images/new-icons/upload.svg" />
          </button>
          <button mat-raised-button class="generate-btn" id="rungap_Button" (click)="onGenerate()"  [ngClass]="{'w-50a': form.get('process').value}"
            [disabled]="fileDetails.length == 0 || form.invalid">
            <span class="gen-text">Run Aspice Analysis</span>
          </button>
          <button mat-raised-button class="clear-btn" (click)="onClear()" *ngIf="form.get('process').value">
          <span class="clear-text"> Clear</span>
         </button>
  
        </div>
      </div>
    </div>
   

    <div class="output-container">
        <div class="top-icon">
          <div class="loading-container mx-2"  *ngIf="isAnalysisInProgress">
            <div class="progress-bar-container mx-4">
              <div 
                class="progress-bar" 
                [class.red]="progressPercentage < 100"
                [class.green]="progressPercentage === 100"
                [style.width]="progressPercentage + '%'">
              </div>
              <div class="loader-text">{{ streamingText }} ({{ progressPercentage }}%)</div>
            </div>
          </div>

          <div class="spacer" *ngIf="!isAnalysisInProgress"></div>
    
        <button mat-raised-button class="generate-btn-text" [disabled]="!isRunAnalysisDone" (click)="startAssessment()">
            <span class="gen-text">Start Assessment</span>
          </button>
    

        </div>
        
 
        <div class="output-body">
            <div class="data-container pt-2">
                <div class="welcome-container" *ngIf="messages.length == 0">
                  <span class="app-title">Assessment</span>
                </div>
            
                <!-- Chat Messages Container -->
                <section class="messages-container" #messageContainer *ngIf="messages.length > 0" (scroll)="onScroll()">
            
                  <!-- Chat Messages Container -->
                  <div class="messages-wrapper" #messageContainer *ngIf="messages.length > 0">
                    <div class="messages-inner">
                      <!-- Message Container -->
                      <div class="message-list">
                        <div *ngFor="let message of messages" class="message-group">
                          <!-- Question Container -->
                          <div class="user-message" *ngIf="message.question">
                            <!-- Logo Container - Now with circular gray background -->
                            <div class="avatar-container">
                              <img src="assets/images/user.svg" alt="Logo Icon" class="avatar-icon" />
                            </div>
            
                            <!-- Question Content with Background -->
                            <div *ngIf="message.question" class="message-content">
                              <div class="message-text">{{message.question}}</div>
                            </div>
                          </div>
            
                          <!-- Answer Container -->
                          <div class="assistant-message" *ngIf="message.showResult">
                            <!-- Logo Container - Now with circular gray background -->
                            <div class="avatar-container">
                              <img src="assets/images/Icon_logo.svg" alt="Logo Icon" class="avatar-icon" />
                            </div>
            
                            <!-- Results Section -->
                            <div class="message-content bg-grey">
                              <div class="message-text">
                                <markdown class="markdown" [data]="message.displayContent"></markdown>
                              </div>
            
                            </div>
                          </div>
                        </div>
                      </div>
            
                    </div>
                  </div>
                </section>
            
                <!-- Fixed Input at Bottom -->
                <div class="text-input-container">
                  <div class="input-wrapper">
                    <div class="input-box">
                      <div class="input-top">
                        <svg xmlns="http://www.w3.org/2000/svg" class="input-icon" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                        </svg>
                        <textarea #messageInput rows="1" [(ngModel)]="newMessage" (input)="autoGrow($event)"
                          (keydown.enter)="sendMessage(); $event.preventDefault()" placeholder="Please Enter Assessment "
                          class="text-input-textarea"></textarea>
                      </div>
            
                      <div class="input-bottom">
                        <button (click)="openPopup1($event)" class="source-btn" [disabled]="!enableFileUpload">
                          <svg xmlns="http://www.w3.org/2000/svg" class="source-icon" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                              d="M16.5 10.5v5a4.5 4.5 0 01-9 0v-7a3 3 0 116 0v6.5a1.5 1.5 0 11-3 0v-6" />
                          </svg>
                          <span class="source-text" *ngIf="enableFileUpload">Please Upload Sample File</span>
                          <div *ngIf="isUploading" class="upload-status">
                            <svg class="loading-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="loading-circle" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="loading-path" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                              </path>
                            </svg>
                            <div class="upload-text">Upload in progress...</div>
                          </div>
                        </button>
            
                        <div class="send-container">
                          <button
                            [disabled]="!newMessage.trim() || !isProcessStarted || (isAtFileUploadStep && !this.uploadedFile )"
                            [ngClass]="{'disabled': !newMessage.trim() || !isProcessStarted || (isAtFileUploadStep && !this.uploadedFile )}"
                            (click)="sendMessage()" class="send-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="send-icon">
                              <rect x="0" y="0" width="24" height="24" class="send-bg" rx="4" />
                              <g transform="scale(0.8) translate(3, 3)">
                                <path d="M22 2L11 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round" />
                                <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round" fill="none" />
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

        </div>

  </div>
  

  
  
  <div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader"></div>
    <div class="loading-text">{{ currentText }}</div>
  </div>
  
