import { IApplicationList, IMainApplicationList, IUserTypesList } from "../interface/shared.interface";
import { environment } from "src/environment/environment";
const TERA_ASSIST_WEB_URL = environment.teraAssistWebURL
const ApplicationList =  <IApplicationList[]>[
    {
      application: "ReqAI",
      application_id: 1,
      logo: "assets/images/reqai.svg",
      route: '../feature-dashboard'
    },
    {
      application: "TeraTestAI",
      application_id: 2,
      logo: "assets/images/testai.svg",
      route: '../feature-dashboard',
    },
    {
      application: "SecurityAI",
      application_id: 3,
      logo: "assets/images/securityAI.svg",
      route: '../feature-dashboard',
    },
    {
      application: "SafetyAI",
      application_id: 4,
      logo: "assets/images/safetyAI.svg",
      route: '../feature-dashboard',
    },
    {
      application: "Compliance",
      application_id: 5,
      logo: "assets/images/comp.svg",
      route: '../feature-dashboard',
    }
  ];

  const UserTypesList =  <IUserTypesList[]>[
    {
      id: 1,
      label: "Admin",
      value: "ADMIN"
    },
    {
      id: 2,
      label: "User",
      value: "USER"
    }
  ];



   const mainApplicationList: IMainApplicationList[] = [
    {
      application: "Tera Assist",
      application_id: 1,
      logo: "assets/images/TeraAssist.svg",
      route: `${TERA_ASSIST_WEB_URL}/features/chat`,
      label: 'Chat with your files using AI'
    },
    {
      application: "TeraAI",
      application_id: 2,
      logo: "assets/images/TeraAI.svg",
      route: '/features/dashboard',
      label: 'AI Assistants for Engineering Tasks'
    }
  ];




  export { ApplicationList, UserTypesList , mainApplicationList };