<div class="global-container">
  <div class="input-container">
    <div class="input-content">
      <div class="header">
        <div class="input-heading m-2 mt-0">Evidence-Analysis</div>
        <div class="settings-ico">
          <mat-icon class="setting-icon">settings</mat-icon>
          <div class="icon-btn-change" (click)="toggleLanguage()">
            <ng-container *ngIf="isEnglish">
                <img class="btn-size" src="assets/images/EN.svg" alt="English" />
            </ng-container>
            <ng-container *ngIf="!isEnglish">
                <img class="btn-size" src="assets/images/DE.svg" alt="German" />
            </ng-container>
        </div>
      </div>
      </div>

      <form [formGroup]="form">
        <div class="drop pt-3">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label> Compliance Category</mat-label>
            <mat-select formControlName="compliance_category" id="compliance_category_dropdown" name="compliance_category_dropdown"
              (selectionChange)="onSelectComplianceCategory($event)">
              <mat-option *ngFor="let option of compliceCategory" [value]="option.id" [id]="option.id"
                [disabled]="!option.is_active">
                {{option.compliance_category}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="drop pt-2">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Process Selection</mat-label>
            <mat-select formControlName="process" id="process_dropdown" name="process_dropdown"
              (selectionChange)="onSelectProcess($event)">
              <mat-option *ngFor="let option of processOptions" [value]="option.process_id" [id]="option.process_id"
                [disabled]="!option.is_available">
                {{option.process_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="input-heading m-2 mt-0">Evidences:</div>
        <div class="file-details-container">
          <ng-container *ngFor="let file of fileDetails; let i = index">
            <div class="file-item">
              <div class="file-icon">
                <img [src]="getFileIconPath(file)" alt="File icon" width="50" height="50">
              </div>
              <div class="file-name" [title]="file.file_name">{{file.file_name}}</div>
            </div>
          </ng-container>
        </div>
      </form>
      <div class="btn-group">
        <button mat-raised-button class="upload-btn" id="Upload_File_Requirements" (click)="openPopup()">
          <img class="upload-img" src="assets/images/new-icons/upload.svg" />
        </button>
        <button mat-raised-button class="generate-btn" id="rungap_Button" (click)="onGenerate()"  [ngClass]="{'w-50a': form.get('process').value}"
          [disabled]="fileDetails.length == 0 || form.invalid">
          <span class="gen-text">Run Evidence Analysis</span>
        </button>
        <button mat-raised-button class="clear-btn" (click)="onClear()" *ngIf="form.get('process').value">
        <span class="clear-text"> Clear</span>
       </button>

      </div>
    </div>
  </div>
  <div class="output-container">
    <div class="top-icon">
      <div class="loading-container mx-2" *ngIf="isStreamingInProgress">
        <div class="progress-bar-container mx-4">
          <div 
            class="progress-bar" 
            [class.red]="progressPercentage < 100"
            [class.green]="progressPercentage === 100"
            [style.width]="progressPercentage + '%'">
          </div>
          <div class="loader-text">{{ streamingText }} ({{ progressPercentage }}%)</div>
        </div>
      </div>
      
      <div class="spacer" *ngIf="!isStreamingInProgress"></div>
      <div>
        <img src="assets/images/history_icon.svg" alt="History" id="History_Button" class="top-right-image"
          (click)="toggleSideWindow()">
      </div>
    </div>
    
    
    <div class="empty" id="run_analysis_Center_Text" *ngIf="!data && !isLoading">
      <img class="empty-image" src="assets/images/new-icons/empty.svg" />
      <span class="empty-description"> Perform evidence analysis of all the processes for the selected compliance category </span>
    </div>
    <div class="output-body" *ngIf="data">
      <ng-container>
        <div class="chart-card px-4">
          <div class="card">
            <div class="chart" id="overallscore">
              <apx-chart class="chart-text-style" [series]="overallscoreoptions.series"
                [chart]="overallscoreoptions.chart" [plotOptions]="overallscoreoptions.plotOptions"
                [labels]="overallscoreoptions.labels" [stroke]="overallscoreoptions.stroke"
                [fill]="overallscoreoptions.fill"></apx-chart>
            </div>
          </div>
          <div class="cards">
            <apx-chart class="chart-text-style" [series]="criteriascoreoptions.series"
              [chart]="criteriascoreoptions.chart" [dataLabels]="criteriascoreoptions.dataLabels"
              [plotOptions]="criteriascoreoptions.plotOptions" [responsive]="criteriascoreoptions.responsive"
              [xaxis]="criteriascoreoptions.xaxis" [fill]="criteriascoreoptions.fill"></apx-chart>
          </div>
        </div>
      </ng-container>
      <div class="data-container">
        <div *ngIf="!isHistory" [innerHTML]="data" class="px-2"> </div>
        <div *ngIf="!isLoading && isHistory" class="pdf-container">
          <iframe *ngIf="pdfUrl" [src]="pdfUrl" width="100%" height="1000px" style="border: none;"></iframe>
        </div>
      </div>
    </div>
    <div class="output-footer" *ngIf="data">
      <button [disabled]="!reportId"  [ngStyle]="{ 'opacity': !reportId ? '0.5' : '1', 'cursor': !reportId ? 'not-allowed' : 'pointer' }" mat-raised-button id="run_analysis_export_Button" class="copy-btn" (click)="onDownloadReport()">
        <span class="copy-text">Export Report</span>
      </button>
    </div>
  </div>
</div>

<div class="side-window" [ngClass]="{'open': isSideWindowOpen}">
  <button class="close-icon" id="History_Close_Button" (click)="toggleSideWindow()">
    &times;
  </button>
  <div class="sidebar-header">
    <img src="assets/images/history_icon.svg" alt="Your Image Alt Text" class="sidebar-image">
    <div class="sidebar-text">History</div>
  </div>
  <div class="side-body-1">
    <div class="top mb-2">
      <ng-container *ngFor="let dateGroup of groupedTopics">
        <div class="date-header">{{ dateGroup.date | isTodayOrTomorrow }}</div>
        <ng-container *ngFor="let topic of dateGroup.topics">
          <div class="text-ellipsis timestamp-container" style="cursor: poine;">
            <div style="cursor: pointer;" (click)="onLoadHistory(topic.report_id)">
              <span class="timestamp">
                {{ topic.created_at | date:'HH:mm' }}&nbsp;&nbsp;
              </span>
              <span class="l-border">
              </span>
              <span class="topic-question" [matTooltip]="topic.report_name">
                {{ topic.report_name }}
              </span>
            </div>
          </div>
          <hr>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <hr>
</div>

<div *ngIf="isLoading" class="loader-container">
  <div class="ring-loader"></div>
  <div class="loading-text">{{ currentText }}</div>
</div>