import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import {
  FormControlName,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, first } from 'rxjs/operators';
import { Observable, fromEvent, merge } from 'rxjs';
import { Login } from 'src/app/core/config/validation.config';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { GenericValidator } from 'src/app/core/utils/directive/generic-validator';
import { IUser, UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environment/environment';
import { USERPROFILE } from 'src/app/core/utils/enum/enum';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {
  loginForm!: UntypedFormGroup;
  submitted = false;
  error = '';
  returnUrl: string = '';
  @ViewChildren(FormControlName, { read: ElementRef }) formControls!: ElementRef[];
  // Use with the generic validation message class
  private validationMessages: { [key: string]: { [key: string]: string } };
  displayMessage: { [key: string]: string } = {};
  private genericValidator: GenericValidator;

  // set the currenr year
  year: number = new Date().getFullYear();
  loading: boolean = false;
  sessionExpired: any = false;
  user_role: any;
  currentUser: any
  hide:boolean = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService, private _uService: UserService
  ) {
    this.sessionExpired = this.authService.getSessionExpired();
    this.validationMessages = Login
    this.currentUser = window.sessionStorage.getItem('currentUser');
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      sEmail: ['', Validators.required],
      sPwd: ['', Validators.required],
    });

  }
  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formControls
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.loginForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(600)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm);
    });
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    } else {
      this.authService.login(this.loginForm.controls['sEmail'].value, this.loginForm.controls['sPwd'].value).subscribe(data => {
           this.loading = false;
           console.log(data,'000000');
           
            let user = data.data;
            if (user) {
              sessionStorage.setItem( 'currentUser', JSON.stringify(user));
              sessionStorage.setItem( 'authuser', JSON.stringify(user.auth_user_id));

              this.authService.currentUserSub(data);
              const currentUser:any = window.sessionStorage.getItem('currentUser');
              let datauser = {
                email: JSON.parse(currentUser).email,
                user_name: user.user_name,
                user_id: user.app_user_id,
                auth_user_id : user.auth_user_id,
                user_type: JSON.parse(currentUser).user_type,
                model_endpoint_active: user.model_endpoint_active
              }
              let obj=<IUser>{};
              obj.currentUser = JSON.stringify(datauser)
              obj.vmStatus = user.model_endpoint_active;
              
              this._uService.setmode(obj); 
              this.router.navigate(['features/dashboard'])
            }
          },
          error => {
            if(error=="Invalid email"){
              this.loginForm.controls['sEmail'].setErrors({ valid: true });
              this.displayMessage = this.genericValidator.processMessages(this.loginForm);
            }  if(error=="Invalid password"){
              this.loginForm.controls['sPwd'].setErrors({ valid: true });
              this.displayMessage = this.genericValidator.processMessages(this.loginForm);
            }
           
            this.loading = false;
            this.error = error ? error : '';
          }
        );
    }
  }
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  getLoading(): boolean {
    return this.loading;
  } 
}
