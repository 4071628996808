<section class="data-container"> 
<div class="assessment-bot-container">
    <div class="assessment-bot-wrapper">
      <div class="header">
        Welcome to the TBCM ASPICE Assessment Bot
      </div>
  
      <div class="content">
        <div class="introduction">
          <h4>Optimize your ASPICE assessments with ease using our expert knowledge based AI Agents!</h4>
        </div>
  
        <div class="section">
          <div class="section-header">Select Your Preferred Language</div>
          <div class="language-options">
            <div *ngFor="let lang of languages" 
                 class="language-option"
                 [class.selected]="selectedLanguage === lang.code"
                 [class.disabled]="lang.disabled"
                 (click)="!lang.disabled && onLanguageSelect(lang.code)">
              {{ lang.name }}
            </div>
          </div>
        </div>
  
        <div class="section" *ngIf="selectedLanguage">
          <div class="agent-section">
            <div class="agent-section-header">
              We have prepared two specialized AI agents to assist you
            </div>
            <div class="agent-options">
              <div *ngFor="let agent of availableAgents" 
                   class="agent-option"
                   [class.selected]="selectedAgent === agent.id"
                   (click)="onAgentSelect(agent.id)">
                <h3>{{ agent.name }}</h3>
                <p>{{ agent.description }}</p>
              </div>
            </div>
          </div>
        </div>
  
        <div class="section" *ngIf="selectedLanguage">
          <div class="section-header">Select Your Workflow Option</div>
          <div class="workflow-options">
            <div *ngFor="let option of workflowOptions" 
                 class="workflow-option"
                 [class.selected]="selectedWorkflowOption === option.id"
                 (click)="onWorkflowOptionSelect(option.id)">
              <h3>{{ option.title }}</h3>
              <p>{{ option.description }}</p>
            </div>
          </div>
        </div>
  
        <div class="start-assessment" *ngIf="selectedLanguage">
          <button mat-raised-button 
                  class="generate-btn" 
                  [disabled]="!isStartAssessmentEnabled()"
                  (click)="startAssessment()">
            <span class="gen-text">Start Assessment</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>