<div class="file-manager">
  <!-- Header -->
  <div class="header">
    <span class="title">Update Or Select Files</span>
    <button class="close-button" (click)="closeModal()">
      <svg xmlns="http://www.w3.org/2000/svg" class="close-icon" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
    </button>
  </div>

  <!-- Tabs -->
  <div class="tabs-container">
    <div class="tabs">
      <button class="tab-button" [class.active-tab]="activeTab === 'selected'" (click)="switchTab('selected')">
        Selected Files ({{selectedFiles.length + tableSelectedFiles.length}})
      </button>
      <button class="tab-button" [class.active-tab]="activeTab === 'all'" (click)="switchTab('all')">
        <div *ngIf="isFileGetLoading" class="loading-spinner-container">
          <svg class="loading-spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="loading-spinner-circle" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
            </circle>
            <path class="loading-spinner-path" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
        </div>
        <span *ngIf="!isFileGetLoading"> All Files ({{apiFiles.length}}) </span>
      </button>
    </div>
  </div>

  <!-- Selected/Upload Tab -->
  <div class="upload-section" *ngIf="activeTab === 'selected'">
    <label class="upload-label">Upload File:</label>
    <div class="drop-area" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
      <input id="fileInput" class="default-file-input" type="file" #fileInput (change)="onFileSelected($event)"
        accept=".docx, .pdf" [multiple]="true" style="display: none;" />
      <button class="choose-button" (click)="fileInput.click()">Choose</button>
      <span class="drag-text"> or Drag and Drop Files Here</span>
    </div>
  </div>

  <!-- Selected Files List -->
  <div class="files-list-container" *ngIf="activeTab === 'selected'">
    <div class="files-list">
      <table class="files-table" *ngIf="selectedFiles.length > 0 || tableSelectedFiles.length > 0">
        <thead class="table-header">
          <tr>
            <th class="p-2">
              <input type="checkbox" [checked]="true" class="rounded border-gray-300" disabled>
            </th>
            <th class="header-cell">File Name</th>
            <th class="header-cell">Status</th>
            <!-- <th class="header-cell">Action</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let file of tableSelectedFiles">
            <tr class="table-row">
              <td class="cell">
                <input type="checkbox" [checked]="file.selected"  [disabled]="file.isUploading" (change)="toggleFileSelection(file)"
                  class="rounded border-gray-300">
              </td>
              <td class="cell">
                <div class="file-info">
                  <svg class="file-icon" fill="#0A3E70" viewBox="0 0 20 20">
                    <path
                      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z">
                    </path>
                  </svg>
                  <span class="file-name">{{file.file_name}}</span>
                </div>
              </td>
              <td class="cell">
                <span>Selected</span>
              </td>
            </tr>
          </ng-container>

          <!-- Display newly uploaded files with progress indicators -->
          <ng-container *ngFor="let file of selectedFiles">
            <tr class="table-row">
              <td class="cell">
                <input type="checkbox" [checked]="file.selected" (change)="toggleSelectedFile(file)"
                  class="rounded border-gray-300">
              </td>
              <td class="cell">
                <div class="file-info">
                  <svg class="file-icon" fill="#0A3E70" viewBox="0 0 20 20">
                    <path
                      d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z">
                    </path>
                  </svg>
                  <span class="file-name">{{file.file_name}}</span>
                </div>
              </td>
              <td class="cell">
                <div *ngIf="file.isUploading" class="file-upload-status">
                  <div class="file-progress-container">
                    <div class="file-progress-bar" [style.width.%]="file.uploadProgress"></div>
                    <span class="file-progress-text">{{file.uploadProgress}}%</span>
                  </div>
                </div>
                <span *ngIf="!file.isUploading">
                  {{ file.uploadProgress === 100 ? 'Selected' : '' }}
                </span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!-- Display message if no files selected -->
      <div *ngIf="selectedFiles.length === 0 && tableSelectedFiles.length === 0" class="no-files-message">
        No files selected
      </div>
    </div>

    <!-- Progress indicator for file uploads -->
    <div *ngIf="isLoading" class="loader-cont">

      <div class="loading-spinner-container">
        <svg class="loading-spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="loading-spinner-circle" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="loading-spinner-path" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
      </div>

      <div class="loading-text">{{ currentText }}</div>
      <div class="progress-container" *ngIf="isProgressPercentage">
        <div class="progress-bar" [style.width.%]="progressPercentage"></div>
        <div class="progress-text" [style.color]="getProgressTextColor()">{{ progressPercentage }}%</div>
      </div>
    </div>
  </div>

  <!-- All Files Tab -->
  <div class="all-files-container" *ngIf="activeTab === 'all'">
    <div class="table-container">
      <table class="files-table">
        <thead class="table-header">
          <tr>
            <th class="p-2">
              <input type="checkbox" [checked]="areAllFilesSelected()" (change)="toggleAllFiles($event)"
                class="rounded border-gray-300">
            </th>
            <th class="header-cell">Name</th>
            <th class="header-cell">Uploaded At</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-row" *ngFor="let file of apiFiles">
            <td class="cell">
              <input type="checkbox" [checked]="file.selected" (change)="toggleFileSelection(file)"
                class="rounded border-gray-300">
            </td>
            <td class="cell">
              <div class="file-info">
                <svg class="file-icon" fill="none" stroke="#0A3E70" viewBox="0 0 20 20">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                    d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z">
                  </path>
                </svg>
                <span class="file-name">{{file.file_name}}</span>
              </div>
            </td>
            <td class="cell">{{file.created_at | date: 'medium'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Footer -->
  <div class="footer">
    <div class="button-group">
      <button class="cancel-button" (click)="closeModal()">Close</button>
      <button class="save-button" 
      [disabled]="(selectedFiles.length === 0 && tableSelectedFiles.length === 0) || areFilesUploading()"
      (click)="proceedFiles()">Save</button>
    </div>
  </div>
</div>