<div class="portal">
    <div class="header-content">
        <app-header></app-header>
    </div>
    <div class="portal-content" fxLayout="column" fxLayoutAlign="start stretch">
        <router-outlet></router-outlet>
    </div>
    <div class="footer-content lite" *ngIf="isTeraUser">
        <span>Copyright © 2025 Teratics All Rights Reserved</span>
    </div>

    <div class="footer-content lite" *ngIf="!isTeraUser">
        <span>Copyright © 2025 TBCM Solutions GmbH</span>
    </div>
</div>