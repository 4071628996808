import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AspiceApiService } from 'src/app/core/services/api/aspice-api.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
import { UploadComponent } from '../../shared/upload/upload.component';
import { FileManagerComponent } from '../../shared/file-manager/file-manager.component';

interface ChatMessage {
  role: 'user' | 'assistant';
  content: any;
  question?: string;
  displayContent?: string;
  timestamp?: string;
  attachments?: FileAttachment[];
  showResult?: boolean;
  isError?: boolean;
}

interface FileAttachment {
  name: string;
  type: string;
  size: number;
  url: string;
  serverUrl?: string;
}
interface ChatTopic {
  id: number;
  topic: string;
  created_at: string;
}

interface TopicsResponse {
  topics: ChatTopic[];
}


interface FileDetail {
  document_id: number;
  file_name: string;
  created_at: string;
  selected: boolean;
  fullPath: string;
  fileType?: string;
}

@Component({
  selector: 'app-tbcm-aspice',
  templateUrl: './tbcm-aspice.component.html',
  styleUrls: ['./tbcm-aspice.component.scss']
})
export class TbcmAspiceComponent {

  fileIcons = {
    pdf: "../assets/images/pdf-icon.svg",
    docx: "../assets/images/word-icon.svg",
  };

  form: FormGroup;
  messages: ChatMessage[] = [];
  newMessage: string = '';
  uploadedFiles: FileAttachment[] = [];
  isUploading: boolean = false;
  userName: any;
  userId: any;
  isExpanded = false;
  showScrollButton: boolean = false;
  showFiller = false;
  isSideNavOpened: boolean = false;
  isLoadingChatHistory: boolean = false;
  isLoadingChat: boolean = false;
  @ViewChild('messageContainer', { static: false }) messageContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('drawer') drawer!: MatDrawer;

  currentChatId: number | null = null;
  chatHistory: ChatTopic[] = [];
 
  noOfCriteira : null;
  isProcessStarted : boolean = false;
  enableFileUpload : boolean = false; 
  isLoading : boolean = false;
  processOptions : any = [];
  selectedProcess = null;
  compliceCategory : any = [];
  selectedComplianceCategory = null;
  assessmentInput: any = [];
  selectedAssessmentInput = null;
  isEnglish : boolean = true;

  fileDetails: FileDetail[] = [];
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  currentText: string;
  progressPercentage = null;
  isProgressPercentage = false;
  isAnalysisInProgress = false;
  streamingText = 'Performing Gap Analysis';
  isRunAnalysisDone : boolean = false;
  constructor(
    private apiService: AspiceApiService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
     private _fb: FormBuilder,
  ) { }

  ngOnInit() {
    const currentUser: any = window.sessionStorage.getItem('currentUser');
    const user = JSON.parse(currentUser);
    this.userName = user.user_name;
    this.userId = user.user_id;
    this.createForm();
    this.fetchProcess();

  }

    createForm() {
      this.form = this._fb.group({
        process: ["", Validators.required],
      });
    }


    getFileIconPath(file: FileDetail): any {
      const extension = this.getFileExtension(file.file_name);
      if (this.fileIcons[extension]) {
        return this.fileIcons[extension];
      }
      return this.fileIcons.pdf;
    }
  
    getFileExtension(fileName: string): string {
      return fileName.split(".").pop().toLowerCase();
    }


  fetchProcess() {
    // this.isLoading = true;

    this.processOptions = [
      {
          "process_id": 12,
          "process_name": "SUP.1 Quality Assurance ",
          "is_available": false
      },
      {
          "process_id": 7,
          "process_name": "SW Architecture Design",
          "is_available": false
      },
      {
          "process_id": 8,
          "process_name": "SW Detailed Design",
          "is_available": false
      },
      {
          "process_id": 10,
          "process_name": "SW Integration & Integration Test",
          "is_available": false
      },
      {
          "process_id": 9,
          "process_name": "SW Module Verification",
          "is_available": false
      },
      {
          "process_id": 6,
          "process_name": "SW Requirement Analysis",
          "is_available": false
      },
      {
          "process_id": 11,
          "process_name": "SW Test",
          "is_available": false
      },
      {
        "process_id": 22,
        "process_name": "QA Strategy",
        "is_available": true
    }
  ]
    // forkJoin({
    //   complianceCategories: this.apiService.apiRequestGet('process_retrieval/compliance_categories'),
    //   processes: this.apiService.apiRequestGet(`process_retrieval/get_process?process_type=${'ASSESSMENT'}`),
    //   assessmentInput : this.apiService.apiRequestGet('process_retrieval/assessment_inputs')
    // }).subscribe({
    //   next: (results) => {
    //     this.compliceCategory = results.complianceCategories;
    //     // this.processOptions = results.processes.processes;
    //     this.assessmentInput = results.assessmentInput
    //     this.isLoading = false;

     
    //   },
    //   error: (err) => {
    //     console.error('Failed to fetch processes', err);
    //     this.isLoading = false;
    //   }
    // });
  }
      
    onSelectProcess(e: any) {
      this.selectedProcess = e.value;
    }
   
    onSelectAssessmentInput(e:any){
      this.selectedAssessmentInput = e.value;
    }

    toggleLanguage() {
      this.isEnglish = !this.isEnglish;
    }

    onClear(){
      this.form.reset();
      this.newMessage = '';
      this.messages = [];
      this.isAnalysisInProgress = false;
      this.isRunAnalysisDone = false;
      this.fileDetails = [];
    }

    startAssessment() {
      this.newMessage = '';
      if (this.form.invalid) {
        this.toastr.error('Please select a process and enter number of criteria', 'Error');
        return;
      }
    
      const assessmentMarkdown = `
    ## ASPICE Process Assessment – SUP.1: Quality Assurance

    ## Assessor:
    Let's begin the evaluation. I will ask you a series of questions to assess how well your software module verification process aligns with ASPICE BP2 criteria. Please provide detailed responses, and I will prepare a report at the end.
    
    ---

    ## Topic 1: Quality Management Strategy
    * Has a strategy for quality assurance in the project been developed?
      `;
    
      this.messages = [];
    
      const assessmentMessage: ChatMessage = {
        role: 'assistant',
        content: assessmentMarkdown,
        displayContent: assessmentMarkdown,
        timestamp: this.getCurrentTime(),
        showResult: true,
        question: "" 
      };
    
      this.messages.push(assessmentMessage);
      this.newMessage = "Yes, a comprehensive quality assurance strategy has been developed for the project.";
      this.isProcessStarted = true;
      this.cdr.detectChanges();
      setTimeout(() => {
        this.scrollToBottom('smooth');
      }, 100);
    }


  onScroll() {
    if (this.messageContainer?.nativeElement) {
      const { scrollTop, scrollHeight, clientHeight } = this.messageContainer.nativeElement;
      this.showScrollButton = scrollHeight - (scrollTop + clientHeight) > 100;
      this.cdr.detectChanges();
    }
  }


  onSelectComplianceCategory(e: any) {
    this.selectedComplianceCategory = e.value;
  }

  ngAfterViewInit() {

  }


  private isNearBottom(): boolean {
    if (this.messageContainer?.nativeElement) {
      const { scrollTop, scrollHeight, clientHeight } = this.messageContainer.nativeElement;
      const scrollThreshold = 200; 
      return scrollHeight - (scrollTop + clientHeight) <= scrollThreshold;
    }
    return false;
  }

  scrollToBottom(behavior: ScrollBehavior = 'smooth'): void {
    if (this.messageContainer?.nativeElement) {
      const container = this.messageContainer.nativeElement;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: behavior
      });
      this.showScrollButton = false;
    }
  }


  handleToggleSideNav() {
    this.isSideNavOpened ? this.drawer.close() : this.drawer.open();
  }

  autoGrow(event: any): void {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  getCurrentTime(): string {
    return new Date().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }




  isAtFileUploadStep: boolean = false;

 
  async sendMessage() {
    if (!this.newMessage.trim() && this.uploadedFiles.length === 0) return;
  
    const question = this.newMessage.trim();
    const wasNearBottom = this.isNearBottom();
    
    // Create user message
    const userMessage: ChatMessage = {
      role: 'user',
      content: question,
      question: question,
      timestamp: this.getCurrentTime()
    };
    
    // Add user message to messages array
    this.messages.push(userMessage);
    
    // Reset textarea
    this.newMessage = '';
    this.uploadedFiles = [];
    
    // Reset textarea height
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.rows = 1;
    }
    
    try {
      await this.simulateDelay(500);
      
      // Find the last assistant message to determine context
      const lastAssistantMessage = this.findLastAssistantMessage();
      
      // QUESTION 1: Quality Management Strategy
      if (!lastAssistantMessage || lastAssistantMessage.content.includes('Topic 1: Quality Management Strategy')) {
        if (question.toLowerCase().includes('yes, a comprehensive quality assurance strategy has been developed for the project.')) {
          const followUpContent = `
            ## Assessor:
            Good! Since you have explicitly developed a comprehensive QA strategy, can you elaborate on:
            
            How do you ensure that quality assurance for work products and processes is carried out independently and without conflicts of interest?
          `;
          
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: followUpContent,
            displayContent: followUpContent,
            timestamp: this.getCurrentTime(),
            showResult: true
          };
          
          this.messages.push(assistantMessage);
          this.newMessage = "Currently, quality assurance activities are performed by team members involved in the development process.";
        }
      }
      
      // QUESTION 2: Independence of QA Activities
      else if (lastAssistantMessage.content.includes('for work products and processes is carried out independently')) {
        if (question.toLowerCase().includes('currently, quality assurance activities are performed by team members involved in the development process.')) {
          const followUpContent = `
            ## Assessor:
            I assume that you might have understood the question correctly. Here is the explanation:
  
            Quality assurance (QA) activities should be conducted by individuals or teams who are not directly responsible for the work products or processes they are evaluating. For example, if a developer is also responsible for reviewing their own code, there is a risk that errors might be overlooked due to familiarity with the work. Ideally, an independent QA team or external auditors should carry out the assessments to maintain objectivity and transparency.
  
            How do you ensure that quality assurance for work products and processes is carried out by independent parties without conflicts of interest? Are there specific measures in place, such as having a separate QA team or external auditors?
          `;
          
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: followUpContent,
            displayContent: followUpContent,
            timestamp: this.getCurrentTime(),
            showResult: true
          };
          
          this.messages.push(assistantMessage);
          this.newMessage = "While efforts are made to minimize conflicts of interest, we acknowledge the need for a more independent QA structure.";
        }
        console.log(this.messages)
      }
      
      // QUESTION 3: Quality of Work Products
      else if (lastAssistantMessage.content.includes('understood the question correctly. Here is the explanation:')) {
        console.log(question)
        if (question.toLowerCase().includes('while efforts are made to minimize conflicts of interest,')) {
          const followUpContent = `
            ## Assessor:
            I will note it down. Let's move on to the next question.
  
            ## Topic 2: Quality of work products
            * Are the QA activities for work products performed according to the project schedule?
          `;
          
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: followUpContent,
            displayContent: followUpContent,
            timestamp: this.getCurrentTime(),
            showResult: true
          };
          
          this.messages.push(assistantMessage);
          console.log(this.messages)
          this.newMessage = "Yes, QA activities for work products are conducted as per the project schedule. Regular reviews and inspections are carried out to ensure compliance with defined quality standards, and any deviations are addressed promptly.";
        }
      }

      
      // QUESTION 4: Quality of Process Activities
      else if (lastAssistantMessage.content.includes('Are the QA activities for work products performed according to the project schedule')) {
        if (question.toLowerCase().includes('yes, qa activities for work products are conducted as per the project schedule')) {
          const followUpContent = `
            ## Assessor:
            That sounds like a positive step forward!
  
            Let's move on to the next question.
  
            ## Topic 3: Quality of process activities
            * How do you ensure that QA activities for process activities align with the defined goals?
          `;
          
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: followUpContent,
            displayContent: followUpContent,
            timestamp: this.getCurrentTime(),
            showResult: true
          };
          
          this.messages.push(assistantMessage);
          this.newMessage = "No, QA activities for process assessments and audits have not been consistently aligned with the defined goals.";
        }
      }
      
      // Final follow-up for process activities
      else if (lastAssistantMessage.content.includes('How do you ensure that QA activities for process activities align with the defined goals')) {
        if (question.toLowerCase().includes('no, qa activities for process assessments and audits have not been consistently aligned')) {
          const followUpContent = `
            ## Assessor:
            I think the question is not clear to you. Here is the explanation:
  
            QA activities for processes, such as audits and assessments, are crucial for ensuring that processes achieve their intended objectives. For example, if a project follows an Agile development process, QA should assess whether Agile principles (such as sprint reviews, retrospectives, and continuous integration) are being properly implemented. If audits or assessments are not carried out as planned, it can lead to process inefficiencies, missed deadlines, or non-compliance with industry standards.
  
            How do you ensure that QA activities for processes, such as process assessments and audits, are conducted as planned and aligned with the project's defined goals? Have there been any challenges in maintaining compliance with standard requirements?
          `;
          
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: followUpContent,
            displayContent: followUpContent,
            timestamp: this.getCurrentTime(),
            showResult: true
          };
          
          this.messages.push(assistantMessage);
          // Prepare for the next response
          this.newMessage = "There have been delays, and some assessments were not conducted as planned, leading to gaps in compliance verification.";
        }
      }
      else if (lastAssistantMessage.content.includes('QA activities for processes, such as audits and assessments')) {
        
        if (question.toLowerCase().includes('there have been delays, and some assessments were not conducted as planned,')) {
          const followUpContent = `
            ## Assessor:
            Then you need to plan and align the QA process activities with your project goals. Please implement improvement measures.

            Let's move on to the next question.

            ---

            Are the results of QA process activities properly documented?
          `;
          
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: followUpContent,
            displayContent: followUpContent,
            timestamp: this.getCurrentTime(),
            showResult: true
          };
          
          this.messages.push(assistantMessage);
          // Prepare for the next response
          this.newMessage = "Yes, all QA process activity results are documented systematically. We maintain detailed records of reviews, audits, and assessments, ensuring traceability and continuous improvement.";
        }
      }
      else if (lastAssistantMessage.content.includes('Then you need to plan and align the QA process activities')) {
        if (question.toLowerCase().includes('yes, all qa process activity results are documented systematically.')) {
          const followUpContent = `
           ## Assessor:
            That's a solid process!

            Here's the final report based on your responses to the questions.



            ## ASPICE Process Assessment – SUP.1: Quality Assurance Strategy

            | Practice | Score (%) | Summary of Responses |
            |----------|-----------|---------------------|
            | Quality Management Strategy | 80% | A clear QA strategy is defined, and an independent team or external auditors handle QA activities.<br><br>**Gaps:** A QA strategy exists, but the same team responsible for development also performs QA, leading to potential bias. |
            | Quality of work products | 100% | QA activities follow the project schedule, with regular reviews ensuring quality standards are met.<br><br>**Gaps:** None |
            | Quality of process activities | 75% | Process audits and assessments are conducted as planned, and results are properly documented for tracking.<br><br>**Gaps:** Some process audits were missed, and documentation is incomplete, making compliance tracking difficult. |
                      `;
          
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: followUpContent,
            displayContent: followUpContent,
            timestamp: this.getCurrentTime(),
            showResult: true
          };
          
          this.messages.push(assistantMessage);
          // Prepare for the next response
          this.newMessage = "";
        }
      }
  
      this.autoGrowTextarea();
      this.cdr.detectChanges();
      
      if (wasNearBottom) {
        requestAnimationFrame(() => this.scrollToBottom('smooth'));
      }
    } catch (error) {
      const assistantMessage: ChatMessage = {
        role: 'assistant',
        content: '',
        displayContent: 'Failed to generate response. Please try again.',
        timestamp: this.getCurrentTime(),
        showResult: true,
        isError: true
      };
      
      this.messages.push(assistantMessage);
      this.cdr.detectChanges();
      this.toastr.error('Failed to generate response', 'Error');
    }
  }
  
  // Helper method to find the last assistant message
  private findLastAssistantMessage(): ChatMessage | undefined {
    for (let i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].role === 'assistant') {
        return this.messages[i];
      }
    }
    return undefined;
  }



  private simulateDelay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }


   openPopup() {
      const uploadConfig: IUploadConfig = {
        accept: ".docx , .pdf",
        isMultiple: true,
        fileType: true,
      };
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = "max-content";
      dialogConfig.panelClass = "info-dialog";
      dialogConfig.data = { uploadConfig };
  
      this.dialog
        .open(FileManagerComponent, dialogConfig)
        .afterClosed()
        .subscribe((res) => {
          if (res && res.length > 0) {
            // Map fields to match FileDetail interface
            this.fileDetails = res.map(file => ({
              document_id: file.document_id || file.file_id,
              file_name: file.file_name,
              created_at: file.created_at,
              selected: file.selected,
              fullPath: file.fullPath
            }));
          }
        });
    }

    
  
  uploadedFile : any
  openPopup1(e: any) {
      const uploadConfig: IUploadConfig = {
          accept: ".docx , .pdf",
          isMultiple: true,
          fileType: true,
        };
        
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content';
    dialogConfig.panelClass = 'info-dialog';
    dialogConfig.data = { uploadConfig };

    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(async result => {
      console.log(result);
      if (result) {
        this.uploadedFile = result.fileDetails[0].fileName;
        
      }
    });
  }


  onGenerate() {
    this.texts = ['Please Wait', 'Analysing Criteria', 'Checking With Aspice Criteria', 'Comparing User Evidences'];
    this.startTextAnimation();
    // this.isLoading = true;
  
    let language = this.isEnglish ? 'ENGLISH' : 'GERMAN'; 
 
    let set = {
      file_id: this.fileDetails.map(data => data.document_id),
      process_id: this.selectedProcess,
      language : language,
      user_id: 1,
      project_id: 1,
    };

    setTimeout(() => {
      this.isLoading  = false;
      this.isAnalysisInProgress = true;
      this.progressPercentage = 1;
      this.startProgressLoader();
    }, 0);

   
  }
 

  startProgressLoader() {
    const stages = [25, 50, 75, 100];
    
    stages.forEach((percentage, index) => {
      setTimeout(() => {
        this.progressPercentage = percentage;
        
        if (index === 0) {
          this.isLoading = false;
          this.isAnalysisInProgress = true;
        }
        
        if (index === stages.length - 1) {
          this.isRunAnalysisDone = true;
          this.streamingText = 'Gap Analysis Completed';

        }
      }, (index + 1) * 5000); // Each stage takes 5 seconds
    });
  }


  startTextAnimation() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }

  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
    this.cdr.detectChanges();
  }

  autoGrowTextarea() {
    setTimeout(() => {
      const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
      if (textarea) {
        // Reset height first
        textarea.style.height = 'auto';
        // Set new height based on content
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    }, 0);
  }

  onEnter(event: KeyboardEvent) {
    if (!event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }
}

