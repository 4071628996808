import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { ApplicationList } from 'src/app/core/utils/data/shared-data';
import { IApplicationList } from 'src/app/core/utils/interface/shared.interface';
import { environment } from "src/environment/environment";
const TERA_ASSIST_WEB_URL = environment.teraAssistWebURL
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  industry: any;
  industryIsEmpty: boolean = true;
  applicationList: IApplicationList[] | [];
  isLoading: boolean = false;
  isTbcmDomain: boolean = false;
  isVmOff : boolean = false;
  isVmOffStatus : boolean = false;
  user:any;
  refershtoken:any;
  authToken:any;
  secretKey = environment.routeSecretKey;
  private teraAssist: IApplicationList = {
    application: "TeraAssist",
    application_id: 'tera-assist',
    encryptedAppId: '',
    logo: "assets/images/TeraAssist.svg",
    route:`${TERA_ASSIST_WEB_URL}/features/chat`
  };
  

  private TBCMAspice : IApplicationList = {
    application: "TBCMAspice",
    application_id: 'tbcm-aspice',
    encryptedAppId: '',
    logo: "assets/images/aspice.svg",
    route:`../tbcm-aspice-dashboard`
  };


  constructor(
    private router: Router,
    private uService: UserService,
    private ecService : EncryptService
  ) { 
  }


  ngOnInit() {
    this.isLoading = true;
    this.teraAssist.encryptedAppId = this.ecService.encrypt(this.teraAssist.application_id.toString());
    this.TBCMAspice.encryptedAppId = this.ecService.encrypt(this.TBCMAspice.application_id.toString());
    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.industryIsEmpty = !this.industry || this.industry.length === 0;
    }); 


    this.uService.isVMoff$.subscribe(status => {
      this.isVmOff = status;
    });
    
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.refershtoken = sessionStorage.getItem('refershtoken');
    this.authToken = sessionStorage.getItem('authorization');
    const currentUser = sessionStorage.getItem('currentUser');
   
    this.uService.app_list$.subscribe(data => {
      if (data && Array.isArray(data)) {
        const processedApps = ApplicationList.map((x) => {
          const matchedApp = data.find(y => y.application_id === x.application_id);
          if (matchedApp) {
            matchedApp.encryptedAppId = this.ecService.encrypt(matchedApp.application_id.toString());
            return { ...x, ...matchedApp };
          }
          return x;
        });


        this.applicationList = [...processedApps, this.teraAssist  , this.TBCMAspice]
       console.log(this.applicationList , 'appp');
    
        this.preloadFeatureImages();
        this.isLoading = false;
      }
    });
  }

  preloadFeatureImages(): void {
    const imageUrls = this.applicationList.map((feature) => feature.logo);
    this.uService.preloadImages(imageUrls);
  }

  navigateToFeature(feature: IApplicationList): void {
    if (feature.application_id === 'tera-assist') {
      const user = {
        "email": this.user?.email,
        "user_name": this.user?.user_name,
        "user_id": this.user?.app_user_id,
        "auth_user_id": this.user?.auth_user_id || null,
        "auth": this.authToken || null,
        "refershtoken": this.refershtoken || null,
      };

      const url = window.location.href

      const userParams = `email=${encodeURIComponent(this.uService.encrypt(user.email, this.secretKey))}` +
        `&user_name=${encodeURIComponent(this.uService.encrypt(user.user_name, this.secretKey))}` +
        `&user_id=${encodeURIComponent(user.user_id)}` +
        `&auth_user_id=${encodeURIComponent(user.auth_user_id)}` +
        `&auth=${user.auth}` +
        `&refresh=${user.refershtoken}`+
        `&fromTeratics=${true}`+
        `&teraTicsURL=${url}`;

      const navigationUrl = `${feature.route}?${userParams}`;
      window.location.href = navigationUrl;
    }
  }

}
