
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexOptions,
  ApexStroke,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title?: ApexTitleSubtitle;
  subtitle?: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  yaxis: ApexYAxis,
  tooltip: ApexTooltip;
  stroke: ApexStroke;
};




export const overallscore = <ApexOptions>{

  series: [],
  chart: {
    height: 250,
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "70%"
      },
      dataLabels: {
        name: {
          fontSize: '10px',
          color: 'gray',
          offsetY: -15
        },
        value: {
          fontSize: '33px',
          color: '#010101',
          offsetY: 10
        },
        total: {
          show: true,
          label: 'Overall Score',
          color: 'gray',
          fontSize: '16px',
          offsetY: -30,
          formatter: function (w) {
            return w.globals.seriesTotals.reduce((a, b) => {
              return a + b
            }, 0) / w.globals.series.length + '%'
          }
        }
      }
    }
  },
  labels: ["Overall Score"]

};



export const overAllpercentage = <ApexOptions>{
  series: [
    {
      name: "",
      data: []
    },
  ],
  chart: {
    type: "bar",
    height: 220,
    stacked: false,
    // stackType: "100%",
    toolbar: {
      show: false,
      tools: {
        download: false,
      }
    }
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '50%',
      dataLabels: {
        position: 'right',
        offsetX: 5,
      },
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val + " %";
    },
    textAnchor: 'middle', // Ensures the label starts after the bar
    offsetX: -10, // Horizontal offset from the end of the bar (positive value moves the label to the right; adjust as needed)
    offsetY: 0,
    align: 'right',
    style: {
      fontSize: '12px',
      colors: ["black"],
      margin: '10px'
    }
  },
  stroke: {
    width: 0,
    colors: ["#fff"]
  },
  title: {
    text: ""
  },
  xaxis: {
    categories: ['Semantics', 'Grammer', 'Plasuibility', 'Consistency', 'Context'],
    labels: {
      show: false, // Hide x-axis labels
      axisTicks: {
        show: false, // Hide the axis ticks
      },
      style: {
        fontSize: '12px', // Adjust font size if needed
        fontWeight: 'bold' // Make labels bold

      }
    },
    max: 100
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val + "%"; // Format y-axis labels as percentages
      },
      style: {
        fontSize: '20px', // Set the font size for y-axis labels (categories)
        fontFamily: 'Manrope, sans-serif',
        color: '#000',
      }
    }
  },
  tooltip: {
    enabled: false,
    y: {
      formatter: function (val) {
        return val + "K";
      }
    }
  },
  fill: {
    opacity: 1
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: 40
  },
  grid: {
    xaxis: {
      lines: {
        show: false // Hide the vertical grid lines if your chart is horizontal
      }
    },
    yaxis: {
      lines: {
        show: false // Hide the horizontal grid lines between the bars
      }
    }
  },

};




export const criteriaChart = {
  series: [
    {
      name: "Completion",
      data: [],
      color: "#21a66a" // Green color for Completion
    },
    {
      name: "Deviation",
      data: [],
      color: "#f55142" // Red color for Deviation
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false 
    }
  },
  plotOptions: {
    bar: {
      dataLabels: {
        position: 'center',
      },
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function(val, opt) {
      return val.toFixed(0) + '%';
    },
    style: {
      fontSize: '10px', // Smaller font size for percentage values
      colors: ['#fff'], // White text for better visibility
      fontWeight: 'normal'
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  xaxis: {
    categories: [
    ],
  },
  fill: {
    opacity: 1
  },
  legend: {
    position: "right",
    offsetX: 0,
    offsetY: 50
  }
};