import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  
   constructor() {
    let obj = <IUser>{};
    obj.currentUser = window.sessionStorage.getItem('currentUser');
    // obj.userProfilePic = JSON.parse(obj?.currentUser)?.profile_pic ? environment.storageUrl + JSON.parse(obj.currentUser).profile_pic : '';
    this.setmode(obj);
  }

  private user = new BehaviorSubject<any>({} as any);
  user$ = this.user.asObservable();


  setmode(data: any) {
      this.user.next(data);
  } 

  private onToggleItem = new BehaviorSubject<any>('');
  $currentToggleItem = this.onToggleItem.asObservable();

  setToggleItem(data:string){
    this.onToggleItem.next(data);
  }

  private industry = new BehaviorSubject<any>({} as any);
  industry$ = this.industry.asObservable();


  setIndustry(data: any) {
      this.industry.next(data);
  } 

  private app_list = new BehaviorSubject<any>({} as any);
  app_list$ = this.app_list.asObservable();


  setAppList(data: any) {
      this.app_list.next(data);
  } 

  private isVMoffSubject = new BehaviorSubject<boolean>(true);
  isVMoff$ = this.isVMoffSubject.asObservable();

  setLLMStatus(status: boolean) {
    this.isVMoffSubject.next(status);
  }

  isNotNull(x: any): boolean {
    return !(x === undefined || x === null || x === "")
  }

  preloadImages(urls: string[]): void {
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }

  encrypt(text: string, skey: string) {
    if (this.isNotNull(text))
        return CryptoJS.AES.encrypt(text, skey.trim()).toString();
    else
        return null;
  }

  decrypt(text: any, skey: string) {
    if (this.isNotNull(text)) {
      const decryptedBytes = CryptoJS.AES.decrypt(text, skey.trim());
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    } else {
      return null;
    }
  }
  

}

export interface IUser {
  currentUser: any;
  vmStatus : boolean;
  userProfilePic : any;
}


