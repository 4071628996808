import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, lastValueFrom } from 'rxjs';
import { UploadComponent } from '../../shared/upload/upload.component';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
import { AspiceApiService } from 'src/app/core/services/api/aspice-api.service';

interface ChatMessage {
  role: 'user' | 'assistant';
  content: any;
  question?: string;
  displayContent?: string;
  timestamp?: string;
  attachments?: FileAttachment[];
  showResult?: boolean;
  isError?: boolean;
  loadingState: number;
}

interface FileAttachment {
  name: string;
  type: string;
  size: number;
  url: string;
  serverUrl?: string;
}
interface ChatTopic {
  id: number;
  topic: string;
  created_at: string;
}

@Component({
  selector: 'app-aspice-assessment',
  templateUrl: './aspice-assessment.component.html',
  styleUrls: ['./aspice-assessment.component.scss']
})
export class AspiceAssessmentComponent {

  form: FormGroup;
  messages: ChatMessage[] = [];
  newMessage: string = '';
  uploadedFiles: FileAttachment[] = [];
  isUploading: boolean = false;
  userName: any;
  userId: any;
  isExpanded = false;
  showScrollButton: boolean = false;
  showFiller = false;
  isSideNavOpened: boolean = false;
  isLoadingChatHistory: boolean = false;
  isLoadingChat: boolean = false;
  @ViewChild('messageContainer', { static: false }) messageContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('drawer') drawer!: MatDrawer;

  currentChatId: number | null = null;
  chatHistory: ChatTopic[] = [];
  isAtFileUploadStep: boolean = false;

  noOfCriteira: null;
  isProcessStarted: boolean = false;
  enableFileUpload: boolean = false;
  isLoading: boolean = false;
  processOptions: any = [];
  selectedProcess = null;
  compliceCategory: any = [];
  selectedComplianceCategory = null;
  assessmentInput: any = [];
  selectedAssessmentInput = null;
  isEnglish: boolean = true;
  assessment_Id = null;
  question_Number = null;
  is_Last_Question = null;
  is_Assessment_Completed : boolean = false;
  report_id = null;
  historyData: any;
  isDownloadEnabled: boolean = false;
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  uploadedFile: any;

  constructor(
    private apiService: AspiceApiService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
  ) { }

  ngOnInit() {
    const currentUser: any = window.sessionStorage.getItem('currentUser');
    const user = JSON.parse(currentUser);
    this.userName = user.user_name;
    this.userId = user.user_id;
    this.createForm();
    this.fetchProcess();
    this.getHistory();

  }

  createForm() {
    this.form = this._fb.group({
      compliance_category: ["", Validators.required],
      process: ["", Validators.required],
      assessmentInput: ["", Validators.required],
      noOfCriteira: ["", [
        Validators.required,
        Validators.pattern(/^\d+$/),
        Validators.min(1),
        Validators.max(10)
      ]]
    });
  }


  fetchProcess() {
    this.isLoading = true;
    forkJoin({
      complianceCategories: this.apiService.apiRequestGet('process_retrieval/compliance_categories'),
      processes: this.apiService.apiRequestGet(`process_retrieval/get_process?process_type=${'ASSESSMENT'}`),
      assessmentInput: this.apiService.apiRequestGet('process_retrieval/assessment_inputs')
    }).subscribe({
      next: (results) => {
        this.compliceCategory = results.complianceCategories;
        this.processOptions = results.processes.processes;
        this.assessmentInput = results.assessmentInput
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Failed to fetch processes', err);
        this.isLoading = false;
      }
    });
  }

  onSelectProcess(e: any) {
    this.selectedProcess = e.value;
  }

  onSelectAssessmentInput(e: any) {
    this.selectedAssessmentInput = e.value;
  }

  toggleLanguage() {
    this.isEnglish = !this.isEnglish;
  }

  onClear() {
    this.form.reset();
    this.newMessage = '';
    this.messages = [];
    this.assessment_Id = null;
    this.question_Number = null;
    this.is_Last_Question = null;
    this.report_id = null;
    this.isDownloadEnabled = false;

  }



  getHistory() {
    this.isLoading = true;
    this.apiService.apiRequestGet(`assessment/user-assessments/${1}`, '',).subscribe(
      (data: any) => {
        if (data) {
          this.historyData = data;
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      },
      err => {
        this.cdr.detectChanges();
        this.isLoading = false;
      }
    );
  }


  startAssessment() {
    this.isDownloadEnabled = false;
    this.isLoadingChat = true;
    this.newMessage = '';
    if (this.form.invalid) {
      this.toastr.error('Please select a process and enter number of criteria', 'Error');
      return;
    }

    this.messages = [];
    let language = this.isEnglish ? 'ENGLISH' : 'GERMAN';

    // Create loading message first
    const assistantMessage: ChatMessage = {
      role: 'assistant',
      content: '',
      displayContent: '',
      timestamp: this.getCurrentTime(),
      showResult: false,
      loadingState: 1
    };

    // Add the loading message to the UI
    this.messages.push(assistantMessage);
    this.cdr.detectChanges();

    // Set data for API request
    let set = {
      assessment_id: this.selectedAssessmentInput,
      process_id: this.selectedProcess,
      compliance_category: this.selectedComplianceCategory,
      language: language,
      user_id: 1,
      project_id: 1,
      no_of_criteria: this.form.value.noOfCriteira
    };

    // Update to second loading state after delay
    setTimeout(() => {
      if(this.isProcessStarted){
        assistantMessage.loadingState = 2;
      }
      this.cdr.detectChanges();
    }, 1000);

    // Make API request
    this.apiService.apiRequestPost(`assessment/initiate/`, '', set).subscribe(
      (data: any) => {
        if (data) {
          this.isLoading = false;
          this.isLoadingChat = false;
          const assessmentMarkdown = data.first_question.question;
          this.assessment_Id = data.assessment_id;
          this.question_Number = data.first_question.question_number;
          this.is_Last_Question = data.first_question.is_last_question;
          this.is_Assessment_Completed = data.is_Assessment_Completed
          this.report_id = data.report_id;
          this.isAtFileUploadStep = data.first_question.is_file_upload;
          this.enableFileUpload = data.first_question.is_file_upload;
          assistantMessage.loadingState = 3;
          assistantMessage.content = assessmentMarkdown;
          assistantMessage.displayContent = assessmentMarkdown;
          assistantMessage.showResult = true;

          
          this.isProcessStarted = true;
          this.cdr.detectChanges();

          setTimeout(() => {
            this.scrollToBottom('smooth');
          }, 100);
        }
      },
      err => {
        // Handle error
        assistantMessage.loadingState = 3;
        assistantMessage.displayContent = 'Failed to generate response. Please try again.';
        assistantMessage.isError = true;
        assistantMessage.showResult = true;
        this.cdr.detectChanges();
        this.isLoading = false;
      }
    );
  }


  onScroll() {
    if (this.messageContainer?.nativeElement) {
      const { scrollTop, scrollHeight, clientHeight } = this.messageContainer.nativeElement;
      this.showScrollButton = scrollHeight - (scrollTop + clientHeight) > 100;
      this.cdr.detectChanges();
    }
  }


  onSelectComplianceCategory(e: any) {
    this.selectedComplianceCategory = e.value;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.drawer && !this.drawer.opened) {
        this.drawer.open();
      }
    });
    this.drawer.openedChange.subscribe((isOpen: boolean) => {
      this.isSideNavOpened = isOpen;
    });
  }


  private isNearBottom(): boolean {
    if (this.messageContainer?.nativeElement) {
      const { scrollTop, scrollHeight, clientHeight } = this.messageContainer.nativeElement;
      const scrollThreshold = 200;
      return scrollHeight - (scrollTop + clientHeight) <= scrollThreshold;
    }
    return false;
  }

  scrollToBottom(behavior: ScrollBehavior = 'smooth'): void {
    if (this.messageContainer?.nativeElement) {
      const container = this.messageContainer.nativeElement;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: behavior
      });
      this.showScrollButton = false;
    }
  }


  handleToggleSideNav() {
    this.isSideNavOpened ? this.drawer.close() : this.drawer.open();
  }

  autoGrow(event: any): void {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  getCurrentTime(): string {
    return new Date().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }


  async loadChat(item: any) {
    this.isLoading = true;
    this.messages = [];
    this.apiService.apiRequestGet(`assessment/assessment-summary/${item.assessment_id}/${1}?process_id=${item.process_id}&report_id=${item.report_id}&language=${item.language}`).subscribe(
      (data: any) => {
        if (data && data.questions) {
          // Set properties for potential later use
          this.assessment_Id = item.assessment_id;
          this.report_id = item.report_id;

          // Create a conversation from the questions data
          Object.values(data.questions).forEach((q: any) => {
            // First, add the assistant's question message
            const assistantMessage: ChatMessage = {
              role: 'assistant',
              content: q.question,
              displayContent: q.question,
              timestamp: this.getCurrentTime(),
              showResult: true,
              loadingState: 3
            };

            this.messages.push(assistantMessage);

            // Then, add the user's answer message
            const userMessage: ChatMessage = {
              role: 'user',
              content: q.answer,
              question: q.answer,
              timestamp: this.getCurrentTime(),
              loadingState: 3
            };

            this.messages.push(userMessage);
          });

          // After displaying all questions and answers, add the summary table
          const summaryMessage: ChatMessage = {
            role: 'assistant',
            content: this.formatSummaryData(data),
            displayContent: this.formatSummaryData(data),
            timestamp: this.getCurrentTime(),
            showResult: true,
            loadingState: 3
          };

          this.messages.push(summaryMessage);

          this.isLoading = false;
          this.cdr.detectChanges();
          this.isDownloadEnabled = true;

          // Scroll to the latest message
          setTimeout(() => {
            this.scrollToBottom('smooth');
          }, 100);
        } else {
          this.toastr.error('No chat data found', 'Error');
          this.isLoading = false;
        }
      },
      err => {
        this.toastr.error('Failed to load chat history', 'Error');
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );
  }





  async sendMessage() {
    if (!this.newMessage.trim() && this.uploadedFiles.length === 0) return;

    const question = this.newMessage.trim();
    const wasNearBottom = this.isNearBottom();
    this.isLoadingChat = true;

    const userMessage: ChatMessage = {
      role: 'user',
      content: question,
      question: question,
      timestamp: this.getCurrentTime(),
      loadingState: 3 
    };

    this.messages.push(userMessage);
    this.cdr.detectChanges();
    let language = this.isEnglish ? 'ENGLISH' : 'GERMAN';
    this.isAtFileUploadStep = false;
    this.enableFileUpload = false;

    let set = {
      assessment_id: this.assessment_Id,
      user_id: 1,
      question_number: this.question_Number,
      answer: question,
      file_name: this.uploadedFile ?  this.uploadedFile : ""
    };

    const assistantMessage: ChatMessage = {
      role: 'assistant',
      content: '',
      displayContent: '',
      timestamp: this.getCurrentTime(),
      showResult: false,
      loadingState: 1
    };

    this.messages.push(assistantMessage);
    this.cdr.detectChanges();

    setTimeout(() => {
      assistantMessage.loadingState = 2;
      this.cdr.detectChanges();
      if (wasNearBottom) {
        this.scrollToBottom('smooth');
      }
    }, 1000);

    if (this.is_Assessment_Completed) {
      // Assessment is already marked as completed, show summary
      this.fetchAndDisplaySummary(assistantMessage, language, wasNearBottom);
    } else {
      this.apiService.apiRequestPost(`assessment/submit-answer/?report_id=${parseInt(this.report_id)}&language=${language}`, '', set).subscribe(
        (data: any) => {
          if (data) {
            // Update assessment completed status based on response
             this.isLoadingChat = false;
            this.is_Assessment_Completed = data.assessment_completed;
            
            if (this.is_Assessment_Completed) {
              // Assessment just completed with this submission, show summary
              this.fetchAndDisplaySummary(assistantMessage, language, wasNearBottom);
            } else {
              // Not completed, show next question
              assistantMessage.loadingState = 3;
              const assessmentMarkdown = data.next_question.question;
              this.question_Number = data.next_question.question_number;
              this.is_Last_Question = data.next_question.is_last_question;
              this.isAtFileUploadStep = data.next_question.is_file_upload;
              this.enableFileUpload = data.next_question.is_file_upload;
              assistantMessage.content = assessmentMarkdown;
              assistantMessage.displayContent = assessmentMarkdown;
              assistantMessage.showResult = true;
              
              this.cdr.detectChanges();
              setTimeout(() => {
                this.scrollToBottom('smooth');
              }, 100);
            }
          }
        },
        err => {
          assistantMessage.loadingState = 3;
          assistantMessage.displayContent = 'Failed to generate response. Please try again.';
          assistantMessage.isError = true;
          assistantMessage.showResult = true;
          this.cdr.detectChanges();
          this.isLoading = false;
        }
      );
    }

    this.newMessage = '';
    this.uploadedFiles = [];
    this.uploadedFile = null;

    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.rows = 1;
    }
  }

  
  private fetchAndDisplaySummary(assistantMessage: ChatMessage, language: string, wasNearBottom: boolean) {
    this.isLoadingChat = true;
    this.apiService.apiRequestGet(`assessment/assessment-summary/${this.assessment_Id}/${1}?process_id=${this.selectedProcess}&report_id=${this.report_id}&language=${language}`).subscribe(
      (data: any) => {
        if (data) {
          assistantMessage.loadingState = 3;
          assistantMessage.content = this.formatSummaryData(data);
          assistantMessage.displayContent = this.formatSummaryData(data);
          assistantMessage.showResult = true;
          this.isDownloadEnabled = true;
          this.isLoadingChat = false;
          this.isAtFileUploadStep = data.isFileUpload;
          this.isProcessStarted = false;
          this.getHistory();
          this.cdr.detectChanges();

          setTimeout(() => {
            this.scrollToBottom('smooth');
          }, 100);
        }
      },
      err => {
        assistantMessage.loadingState = 3;
        assistantMessage.displayContent = 'Failed to generate response. Please try again.';
        assistantMessage.isError = true;
        assistantMessage.showResult = true;
        this.cdr.detectChanges();
        this.isLoading = false;
      }
    );
  }


  formatSummaryData(data: any): string {

    let markdown = `## ${data.process_name}\n\n`;
    markdown += '|   Criteria  |       Score    | Summary of Responses | Comments (Links/Documents Provided) |\n';
    markdown += '|-------------|----------------|----------------------|------------------------------------|\n';

    // data  questions property with array of questions
    if (data.summary.criteria_summaries) {
      Object.values(data.summary.criteria_summaries).forEach((q: any, index) => {
        markdown += `| ${q.criteria} | ${q.score}% | ${q.summary_of_responses} | ${q.comments} |\n`;
      });
    }

    return markdown;
  }


  onDownload() {
    import('jspdf').then((jsPDF) => {
      import('html2canvas').then((html2canvas) => {
        const PDF = new jsPDF.default('p', 'mm', 'a4');
  
        // Find the rendered markdown containing the table
        const markdownElements = document.querySelectorAll('.markdown');
        let tableElement: HTMLElement | null = null;
        let processName: string | null = null;
  
        // Look through all markdown elements to find the one with our table
        markdownElements.forEach((element) => {
          const table = element.querySelector('table');
          if (table) {
            tableElement = table as HTMLElement;
            
            // Try to find the process name from the heading above the table
            const heading = element.querySelector('h2');
            if (heading) {
              processName = heading.textContent;
            }
          }
        });
  
        if (!tableElement) {
          // Get the summary content from the last assistant message that has a table structure
          const markdownContent = this.messages.find(m =>
            m.role === 'assistant' &&
            m.showResult &&
            m.content.includes('|') &&
            m.content.includes('\n|')
          )?.content;
  
          if (markdownContent) {
            // Extract process name from markdown content (from heading)
            const headingMatch = markdownContent.match(/##\s*(.*?)(\n|$)/);
            if (headingMatch && headingMatch[1]) {
              processName = headingMatch[1].trim();
            } else {
              processName = "Process Assessment";
            }
  
            // Create basic PDF with text content
            PDF.setFontSize(16);
            PDF.text(processName, 14, 15);
  
            // Split markdown content into lines
            const lines = markdownContent.split('\n').filter(line => line.trim() !== '');
            let y = 25;
  
            lines.forEach(line => {
              if (line.startsWith('##')) {
                // Skip the heading as we've already added it as the title
                return;
              } else if (line.startsWith('|')) {
                // Handle table row
                const cells = line.split('|').filter(cell => cell.trim() !== '');
                if (cells.length > 0) {
                  const rowText = cells.map(cell => cell.trim()).join(' | ');
                  PDF.text(rowText, 14, y, { maxWidth: 180 });
                }
              } else {
                // Regular text
                PDF.text(line, 14, y, { maxWidth: 180 });
              }
  
              y += 7;
              if (y > 280) {
                PDF.addPage();
                y = 15;
              }
            });
  
            // Save the PDF with the process name in the filename
            const safeProcessName = processName.replace(/[^a-z0-9]/gi, '_').substring(0, 30);
            const fileName = `${safeProcessName}_${new Date().toISOString().slice(0, 10)}.pdf`;
            PDF.save(fileName);
            return;
          }
  
          alert('Could not find any table data to generate PDF.');
          return;
        }
  
        // If table was found, use the process name we extracted earlier
        if (!processName) {
          // Fallback if we couldn't find a process name
          processName = "Process Assessment";
        }
  
        // Add title
        PDF.setFontSize(16);
        PDF.text(processName, 14, 15);
  
        // Convert table to canvas and add to PDF
        html2canvas.default(tableElement).then(canvas => {
          // Calculate dimensions to fit on PDF
          const imgWidth = 190;
          const pageHeight = 290;
          const imgHeight = canvas.height * imgWidth / canvas.width;
  
          // Add canvas as image to PDF
          const imgData = canvas.toDataURL('image/png');
          PDF.addImage(imgData, 'PNG', 10, 25, imgWidth, imgHeight);
  
          // Handle multi-page tables
          let heightLeft = imgHeight;
          let position = 25;
          heightLeft -= pageHeight;
  
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            PDF.addPage();
            PDF.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
  
          // Save the PDF
          const safeProcessName = processName.replace(/[^a-z0-9]/gi, '_').substring(0, 30);
          const fileName = `${safeProcessName}_${new Date().toISOString().slice(0, 10)}.pdf`;
          PDF.save(fileName);
        });
      });
    });
  }


  private simulateDelay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }


  openPopup(e: any) {
    const uploadConfig: IUploadConfig = {
      accept: ".docx , .pdf",
      isMultiple: true,
      fileType: true,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content';
    dialogConfig.panelClass = 'info-dialog';
    dialogConfig.data = { uploadConfig };

    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.uploadedFile = result.fileDetails[0].fileName;
      }
    });
  }



  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }

  autoGrowTextarea() {
    setTimeout(() => {
      const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    }, 0);
  }

  onEnter(event: KeyboardEvent) {
    if (!event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }
}
