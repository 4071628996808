import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeaturesRoutingModule } from './features-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GenerateRequirementsComponent } from './generate-requirements/generate-requirements.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from '../shared/shared.module';
import { ReviewRequirementsComponent } from './review-requirements/review-requirements.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InfoDashboardComponent } from './info-dashboard/info-dashboard.component';
import { ClassifyRequirementsComponent } from './classify-requirements/classify-requirements.component';
import { GroupRequirementsComponent } from './group-requirements/group-requirements.component';
import { TableModule } from 'ngx-easy-table';
import { GenerateVerificationComponent } from './generate-verification/generate-verification.component';
import { SummarizeQaComponent } from './summarize-qa/summarize-qa.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { GenerateUmlComponent } from './generate-uml/generate-uml.component';
import { MarkdownModule } from 'ngx-markdown';
import { IsTodayOrTomorrowPipe } from '../shared/pipe/is-today-or-tomorrow.pipe';
import { LazyLoadDirective } from 'src/app/core/utils/directive/lazy-load.directive';
import { RequirementComparisonComponent } from './requirement-comparison/requirement-comparison.component';
import { HaraUsingHazopComponent } from './hara-using-hazop/hara-using-hazop.component';
import { FMEAComponent } from './fmea/fmea.component';
import { CybersecurityTaraComponent } from './cybersecurity-tara/cybersecurity-tara.component';
import { capitalizeFirstPipe } from '../shared/pipe/capitalize-first.pipe';
import { FunctionalTestcaseComponent } from './functional-testcase/functional-testcase.component';
import { RegAiDashboardComponent } from './reg-ai-dashboard/reg-ai-dashboard.component';
import { TeraTestAiDashboardComponent } from './tera-test-ai-dashboard/tera-test-ai-dashboard.component';
import { TeratestReviewRequirementComponent } from './teratest-review-requirement/teratest-review-requirement.component';
import { TeratestFuntionalTestcaseComponent } from './teratest-funtional-testcase/teratest-funtional-testcase.component';
import { TeratestGenerateTestcaseComponent } from './teratest-generate-testcase/teratest-generate-testcase.component';
import { FeatureDashboardComponent } from './feature-dashboard/feature-dashboard.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { GapAnalysisComponent } from './gap-analysis/gap-analysis.component';
import { AspiceAssessmentComponent } from './aspice-assessment/aspice-assessment.component';
import { SafehtmlPipe } from 'src/app/core/services/safehtml.pipe';
import { TbcmAspiceDashboardComponent } from './tbcm-aspice-dashboard/tbcm-aspice-dashboard.component';
import { TbcmAspiceComponent } from './tbcm-aspice/tbcm-aspice.component';

@NgModule({
  declarations: [
    DashboardComponent,
    GenerateRequirementsComponent,
    ReviewRequirementsComponent,
    InfoDashboardComponent,
    ClassifyRequirementsComponent,
    GroupRequirementsComponent,
    GenerateVerificationComponent,
    SummarizeQaComponent,
    GenerateUmlComponent,
    IsTodayOrTomorrowPipe,
    LazyLoadDirective,
    RequirementComparisonComponent,
    HaraUsingHazopComponent,
    FMEAComponent,
    CybersecurityTaraComponent,
    capitalizeFirstPipe,
    FunctionalTestcaseComponent,
    RegAiDashboardComponent,
    TeraTestAiDashboardComponent,
    TeratestReviewRequirementComponent,
    TeratestFuntionalTestcaseComponent,
    TeratestGenerateTestcaseComponent,
    FeatureDashboardComponent,
    MainDashboardComponent,
    GapAnalysisComponent,
    AspiceAssessmentComponent,
    SafehtmlPipe,
    TbcmAspiceDashboardComponent,
    TbcmAspiceComponent
  ],
  imports: [
    CommonModule,
    FeaturesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    SharedModule,
    NgApexchartsModule,
    TableModule,
    NgxTypedJsModule,
    MarkdownModule.forRoot()
  ]
})
export class FeaturesModule { }
