import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { IFeatureList } from 'src/app/core/utils/interface/shared.interface';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';

@Component({
  selector: 'app-feature-dashboard',
  templateUrl: './feature-dashboard.component.html',
  styleUrls: ['./feature-dashboard.component.scss']
})
export class FeatureDashboardComponent {

  industry: any;
  industryIsEmpty: boolean = true;
  featuresList: IFeatureList[]
  isLoading: boolean = false;

  data = [
    { id: 'Generate_Requirement', name: 'Generate Requirement', logo: "assets/images/Generate Requirements_Iconset.svg", route: '../gen-req', description: 'Convert natural language inputs into formal requirements specifications.' },
    { id: 'Review_Requirements', name: 'Review Requirements', logo: "assets/images/Review Requirements_Iconset.svg", route: '../rev-req', description: 'Review and improve ambiguous, incomplete, incorrect, illogically formatted requirements.' },
    { id: 'Classify_Requirements', name: 'Classify Requirements', logo: "assets/images/Classify Requirements_Iconset.svg", route: '../class-req', description: 'Classify requirements into different categories (Calibration, Functional, Non-Functional, etc.)' },
    { id: 'Group_Requirements', name: 'Group Requirements', logo: "assets/images/Group Requirements_Iconset.svg", route: '../group-req', description: 'Group similar requirements together (e.g. vehicle or SW feature based grouping).' },
    { id: 'Generate_Verification_Criteria', name: 'Generate Verification Criteria', logo: "assets/images/Generate Verification Criteria_Iconset.svg", route: '../gen-ver', description: 'Generate verification criteria for individual requirements as per pre-defined template.' },
    { id: 'Summarize_Requirements_and_Q&_A', name: 'Summarize and Q&A', logo: "assets/images/Summarize Requirements & QA_Iconset.svg", route: '../summarize-qa', description: 'Provide a brief summary of the requirements and provide answers for user specific question.' },
    { id: 'Generate_System_Architectural_Design', name: 'Generate System Design ', logo: "assets/images/Generate System Design_Iconset.svg", route: '../generate-uml', description: 'Convert textual requirements into behavioural models such as UML, SysML, etc.' },
    { id: 'Compare_Requirements', name: 'Compare Requirements ', logo: "assets/images/compare-req.svg", route: '../compare-req', description: 'Compare new requirements with existing requirements to find similarities.' },
    { id: 'HARA_Using_HAZOP', name: 'HARA using HAZOP', logo: "assets/images/hazop.svg", route: '../hazop', description: 'Derive HARA and safety goals for the given functional requirements.' },
    { id: 'FMEA', name: 'FMEA', logo: "assets/images/fmea.svg", route: '../fmea', description: 'Analyze failure modes, theirs effects on the system and their root causes' },
    { id: 'Cybersecurity_TARA', name: 'Cybersecurity - TARA', logo: "assets/images/TARA.svg", route: '../cyber-tara', description: 'Analyse threats and assess risks for the given asset and attack scenarios' },
    { id: 'Functional_Test_Cases', name: 'Functional Test Cases', logo: "assets/images/functional-testcase.svg", route: '../functional-test-case', description: 'Derive functional test cases from system requirements' },
  ];

  constructor(
    private router: Router,
    private uService: UserService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private ecService : EncryptService
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      const app_id = this.ecService.decrypt(params['application_id']);
      this.getFeatureList(app_id);
      console.log(app_id);
      
    })
  }

  ngOnInit() {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.industryIsEmpty = !this.industry || this.industry.length === 0; // Set flag based on industry data
    });
    // this.preloadFeatureImages();
  }


  getFeatureList(id: number | string) {
    this.isLoading = true;
    const queryParam = `?app_id=${id}`;
    this.apiService.apiRequestGet('entity_service/features', queryParam).subscribe(data => {
      if (data && Array.isArray(data.features)) {
        this.featuresList = data.features;
        this.isLoading = false;
      }
    });
  }

  preloadFeatureImages(): void {
    const imageUrls = this.data.map((feature) => feature.logo);
    this.uService.preloadImages(imageUrls);
  }

}
