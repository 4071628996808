import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AspiceApiService } from 'src/app/core/services/api/aspice-api.service';

export interface DeleteResponse {
  message: string;
}

export interface FileItem {
  file_id?: number;
  document_id?: number;
  file_name: string;
  type?: string;
  directory?: string;
  created_at: string;
  selected?: boolean;
  is_selected?: boolean;
  file?: File;
  isDeleting?: boolean;
  isUploading?: boolean;
  uploadProgress?: number;
  fullPath?: string;
  isNewlyUploaded?: boolean;
  existsOnServer?: boolean;
}

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {

  activeTab: 'selected' | 'all' = 'selected';
  selectedFiles: FileItem[] = [];
  tableSelectedFiles: FileItem[] = [];
  apiFiles: FileItem[] = [];
  isLoading: boolean = false;
  isDeleting: boolean = false;
  isFileGetLoading: boolean = false;
  errorMessage: string = '';
  uploadedFileIds: number[] = [];
  existingFileIds: number[] = [];
  currentText: string;
  intervalId: any;
  progressPercentage = null;
  isProgressPercentage = false;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private http: HttpClient,
    private ApiService: AspiceApiService,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private dialogref: MatDialogRef<FileManagerComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) { }

  ngOnInit() {
    this.fetchFiles();
  }


  fetchFiles(): void {
    this.isFileGetLoading = true;
    this.ApiService.apiRequestGet(`documents/list-user-documents?&user_id=${1}&project_id=${1}`).subscribe(
      (response: any) => {

        if (this.existingFileIds.length === 0) {
          this.existingFileIds = response.map(file => file.document_id);
        }

        this.apiFiles = response.map((file: any) => {
          const isNewlyUploaded = !this.existingFileIds.includes(file.document_id);

          return {
            ...file,
            file_id: file.document_id,
            selected: isNewlyUploaded || file.selected || false,
            fullPath: `${file.file_name}`,
            isNewlyUploaded: isNewlyUploaded
          };
        });

        this.updateTableSelectedFiles();
        this.isFileGetLoading = false;
      },
      (error) => {
        console.error('Error fetching files:', error);
        this.isFileGetLoading = false;
      }
    );
  }


  clearNewlyUploadedStatus(): void {
    this.uploadedFileIds = [];

    this.apiFiles.forEach(file => {
      file.isNewlyUploaded = false;
    });
    this.updateTableSelectedFiles();
  }


  deleteFile(file: FileItem): void {
    file.isDeleting = true;
    this.errorMessage = '';

    this.ApiService.apiRequestDelete(`/conversation/delete_file?file_id=${file.file_id}`).subscribe({
      next: (response: any) => {
        // Remove from both arrays
        this.apiFiles = this.apiFiles.filter(f => f.file_id !== file.file_id);
        this.selectedFiles = this.selectedFiles.filter(f => f.file_id !== file.file_id);
        this.tableSelectedFiles = this.tableSelectedFiles.filter(f => f.file_id !== file.file_id);
        this.toastr.success(`File deleted successfully`, 'Success');
      },
      error: (error) => {
        this.errorMessage = 'Failed to delete file. Please try again.';
        this.toastr.error(`Failed to delete file. Please try again`, 'Error');
        file.isDeleting = false;
      },
      complete: () => {
        file.isDeleting = false;
      }
    });
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.addFilesToUploadQueue(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.addFilesToUploadQueue(files);
    }
  }

  addFilesToUploadQueue(files: FileList): void {
    this.activeTab = 'selected';

    const newFiles: FileItem[] = Array.from(files).map(file => ({
      file_id: Date.now() + Math.floor(Math.random() * 1000),
      file_name: file.name,
      type: file.type,
      directory: 'uploads/temp_files',
      created_at: new Date().toISOString(),
      selected: true,
      file: file,
      isUploading: true,
      uploadProgress: 0,
      isNewlyUploaded: true
    }));

    this.selectedFiles.push(...newFiles);

    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });

    this.uploadFiles(formData, newFiles);
  }



  uploadFiles(formData: FormData, filesToUpload: FileItem[]): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }

    // Start the upload process
    if (formData.has('files')) {
      this.isLoading = true;
      this.currentText = "Uploading files";

      // Process the incoming stream line by line
      const onLine = (line: string) => {
        if (line.startsWith('data:')) {
          try {
            const jsonStr = line.substring(5).trim();
            if (jsonStr) {
              const data = JSON.parse(jsonStr);

              // Update progress for all files
              if (data?.progress !== undefined) {
                const progress = Math.round(data.progress);
                filesToUpload.forEach(file => {
                  file.uploadProgress = progress;
                });
              }

              // Update status message
              if (data?.message) {
                this.currentText = data.message;
              }

              // For completed uploads, track file IDs if available
              if (data?.file_ids) {
                this.uploadedFileIds = [...this.uploadedFileIds, ...data.file_ids];

                data.file_ids.forEach(fileId => {
                  const existingFile = this.apiFiles.find(f => f.file_id === fileId);
                  if (existingFile) {
                    existingFile.selected = true;
                    existingFile.isNewlyUploaded = true;
                  }
                });

                // Force update the selected files table
                this.updateTableSelectedFiles();
              }

              // Force Angular to update the view
              this.ngZone.run(() => {
                this.cdr.detectChanges();
              });
            }
          } catch (e) {
            console.error('Error processing event data:', e);
          }
        }
      };

      this.ApiService.apiRequestPostEventStream(
        'documents/upload-documents',
        `?user_id=${1}&project_id=${1}&process_id=${5}`,
        formData
      ).subscribe({
        next: (eventData) => {
          onLine(eventData);
        },
        error: (err) => {
          console.error('Upload error:', err);
          this.isLoading = false;

          // Mark all files as failed
          filesToUpload.forEach(file => {
            file.isUploading = false;
            file.uploadProgress = 0;
          });

          this.toastr.error('Error!', 'Upload failed.');
          this.fetchFiles();
          if (this.fileInput) {
            this.fileInput.nativeElement.value = '';
          }
          this.cdr.detectChanges();
        },
        complete: () => {
          // Mark files as completed
          filesToUpload.forEach(file => {
            file.isUploading = false;
            file.uploadProgress = 100;
            file.selected = true;
            file.existsOnServer = true;
          });

          this.isLoading = false;
          this.toastr.success('Upload complete', 'Files processed successfully');

          // Get the files list again
          this.ApiService.apiRequestGet(`documents/list-user-documents?&user_id=${1}&project_id=${1}`).subscribe(
            (response: any) => {

              const newFiles = response.filter(file => !this.existingFileIds.includes(file.document_id));

              this.selectedFiles = this.selectedFiles.filter(file => !file.existsOnServer);

              this.apiFiles = response.map((file: any) => {
                const isNewFile = !this.existingFileIds.includes(file.document_id);

                return {
                  ...file,
                  file_id: file.document_id,
                  selected: isNewFile || this.isFileSelected(file.document_id),
                  fullPath: `${file.file_name}`,
                  isNewlyUploaded: isNewFile
                };
              });

              this.existingFileIds = response.map(file => file.document_id);

              this.updateTableSelectedFiles();

              if (this.fileInput) {
                this.fileInput.nativeElement.value = '';
              }
              this.cdr.detectChanges();
            },
            (error) => {
              console.error('Error refreshing files:', error);
            }
          );
        }
      });
    }
  }


  isFileSelected(fileId: number): boolean {
    const file = this.apiFiles.find(f => f.file_id === fileId);
    return file ? file.selected : false;
  }

  resetFileTracking(): void {
    this.existingFileIds = this.apiFiles.map(file => file.file_id);
    this.apiFiles.forEach(file => {
      file.isNewlyUploaded = false;
    });
    this.updateTableSelectedFiles();
  }

  switchTab(tab: 'selected' | 'all'): void {
    this.activeTab = tab;
    if (tab === 'selected') {
      this.updateTableSelectedFiles();
    } else {
      // Don't reset tracking when switching to All Files tab
    }
  }

  toggleSelectedFile(file: FileItem): void {
    if (file.isUploading) {
      return;
    }
    
    file.selected = !file.selected;
    if (this.activeTab === 'selected' && !file.selected) {
      this.selectedFiles = this.selectedFiles.filter(f => f.file_id !== file.file_id);
    }
  
    this.updateTableSelectedFiles();
  }

  removeFile(file: FileItem) {
    // Remove file from selectedFiles
    this.selectedFiles = this.selectedFiles.filter(f => f.file_id !== file.file_id);

    const apiFile = this.apiFiles.find(f => f.file_id === file.file_id);
    if (apiFile) {
      apiFile.selected = false;
    }

    this.updateTableSelectedFiles();
  }

  toggleFileSelection(file: FileItem) {
    file.selected = !file.selected;
    this.updateTableSelectedFiles();
  }

  toggleAllFiles(event: any) {
    const checked = event.target.checked;
    this.apiFiles.forEach(file => {
      file.selected = checked;
    });
    this.updateTableSelectedFiles();
  }

  updateTableSelectedFiles() {
    this.tableSelectedFiles = this.apiFiles.filter(file => file.selected);
  }

  areAllFilesSelected(): boolean {
    return this.apiFiles.length > 0 && this.apiFiles.every(file => file.selected);
  }

  formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  closeModal() {
    this.dialogref.close();
  }

  proceedFiles() {
    const localSelectedFiles = this.selectedFiles.filter(file =>
      file.selected && !file.existsOnServer && !file.file_id.toString().includes('.')
    );

    // Combine with server files
    const allSelectedFiles = [
      ...this.tableSelectedFiles,
      ...localSelectedFiles
    ];

    this.dialogref.close(allSelectedFiles.length > 0 ? allSelectedFiles : null);
  }

  areFilesUploading(): boolean {
    return this.selectedFiles.some(file => file.isUploading);
  }

  cancel() {
    this.dialogref.close(null);
  }

  getProgressTextColor(): string {
    return this.progressPercentage > 50 ? '#fff' : '#0B3F70';
  }
}