import { ChangeDetectorRef, Component, NgZone, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/core/services/user/user.service";
import { IUploadConfig } from "src/app/core/utils/interface/shared.interface";
import { AspiceApiService } from "src/app/core/services/api/aspice-api.service";
import { FileManagerComponent } from "../../shared/file-manager/file-manager.component";
import { ChartComponent } from "ng-apexcharts";
import { criteriaChart, overallscore } from "src/app/core/utils/data/chart-data";
import { DomSanitizer } from "@angular/platform-browser";
import { forkJoin } from 'rxjs';
interface FileDetail {
  document_id: number;
  file_name: string;
  created_at: string;
  selected: boolean;
  fullPath: string;
  fileType?: string;
}

@Component({
  selector: "app-gap-analysis",
  templateUrl: "./gap-analysis.component.html",
  styleUrls: ["./gap-analysis.component.scss"],
})
export class GapAnalysisComponent {
  form: FormGroup;
  historyNav: boolean = false;
  isLoading: boolean = false;
  data: string = '';
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  industry: any;
  topics: any = [];
  groupedTopics = [];
  isSideWindowOpen: boolean = false;
  processOptions: any = [];
  compliceCategory : any = [];
  fileDetails: FileDetail[] = [];
  fileDatas: any;
  selectedProcess: null;
  selectedComplianceCategory: null;
  progressPercentage = null;
  isProgressPercentage = false;
  analysisResults: any;
  isStreamingInProgress = false;
  streamingText = 'Performing Gap Analysis';
  reportId: any = null;
  pdfUrl: any
  isHistory: boolean = false;
  isError : boolean = false;
  isEnglish : boolean = true;
  // Sample dataset for criteria
  private fullCriteriaData = {
    completion: [40, 88, 95, 85, 90, 50, 55, 92, 90, 89, 80, 88, 95, 85, 90, 90, 60, 92, 90, 89],
    deviation: [60, 12, 5, 15, 10, 50, 45, 8, 10, 11, 20, 12, 5, 15, 10, 10, 40, 8, 10, 11],
    categories: [
      "Criteria 1", "Criteria 2", "Criteria 3", "Criteria 4", "Criteria 5",
      "Criteria 6", "Criteria 7", "Criteria 8", "Criteria 9", "Criteria 10",
      "Criteria 11", "Criteria 12", "Criteria 13", "Criteria 14", "Criteria 15",
      "Criteria 16", "Criteria 17", "Criteria 18", "Criteria 19", "Criteria 20"
    ]
  };

  fileIcons = {
    pdf: "../assets/images/pdf-icon.svg",
    docx: "../assets/images/word-icon.svg",
  };

  @ViewChild("overallscore") overallscore: ChartComponent;
  @ViewChild("criteriascore") criteriascore: ChartComponent;

  public overallscoreoptions: Partial<any>;
  public criteriascoreoptions: Partial<any>;

  constructor(
    private apiService: AspiceApiService,
    private uService: UserService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer
  ) {
    this.overallscoreoptions = overallscore;
    this.criteriascoreoptions = criteriaChart;
  }

  ngOnInit(): void {
    this.createForm();
    this.uService.industry$.subscribe((data) => {
      this.industry = data;
    });

    this.fetchProcess();
    this.loadTopics();
    this.initializeCharts();
  }

  toggleLanguage() {
    this.isEnglish = !this.isEnglish;
  }

  initializeCharts(): void {
    this.criteriascoreoptions = {
      ...this.criteriascoreoptions,
      series: [
        {
          name: "Completion",
          data: [],
          color: "#21a66a"
        },
        {
          name: "Deviation",
          data: [],
          color: "#f55142"
        }
      ],
      xaxis: {
        categories: [
          "Criteria 1", "Criteria 2", "Criteria 3", "Criteria 4", "Criteria 5",
          "Criteria 6", "Criteria 7", "Criteria 8", "Criteria 9", "Criteria 10",
          "Criteria 11", "Criteria 12", "Criteria 13", "Criteria 14", "Criteria 15",
          "Criteria 16", "Criteria 17", "Criteria 18", "Criteria 19", "Criteria 20"
        ]
      }
    };
    
    // Initialize overall score chart with 0
    this.overallscoreoptions = {
      ...this.overallscoreoptions,
      series: [0]
    };
  }

  createForm() {
    this.form = this._fb.group({
      compliance_category: ["", Validators.required],
      process: ["", Validators.required],
    });
  }


  fetchProcess() {
    this.isLoading = true;
    forkJoin({
      complianceCategories: this.apiService.apiRequestGet('process_retrieval/compliance_categories'),
      processes: this.apiService.apiRequestGet(`process_retrieval/get_process?process_type=${'GAPANALYSIS'}`)
    }).subscribe({
      next: (results) => {
        this.compliceCategory = results.complianceCategories;
        this.processOptions = results.processes.processes;
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Failed to fetch processes', err);
        this.isLoading = false;
      }
    });
  }

  toggleSideWindow(): void {
    this.isSideWindowOpen = !this.isSideWindowOpen;
  }

  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: ".docx , .pdf",
      isMultiple: true,
      fileType: true,
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "max-content";
    dialogConfig.panelClass = "info-dialog";
    dialogConfig.data = { uploadConfig };

    this.dialog
      .open(FileManagerComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res && res.length > 0) {
          // Map fields to match FileDetail interface
          this.fileDetails = res.map(file => ({
            document_id: file.document_id || file.file_id,
            file_name: file.file_name,
            created_at: file.created_at,
            selected: file.selected,
            fullPath: file.fullPath
          }));
        }
      });
  }

  getFileIconPath(file: FileDetail): any {
    const extension = this.getFileExtension(file.file_name);
    if (this.fileIcons[extension]) {
      return this.fileIcons[extension];
    }
    return this.fileIcons.pdf;
  }

  getFileExtension(fileName: string): string {
    return fileName.split(".").pop().toLowerCase();
  }

  loadTopics() {
    this.apiService.apiRequestGet(`reports/list-reports?&user_id=${1}&project_id=${1}`).subscribe(
      data => {
        const parsedData = data;
        this.topics = parsedData?.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        this.groupTopicsByDate();
      },
      error => {
        console.error('Failed to fetch reports', error);
        this.isLoading = false;
      }
    );
  }

  groupTopicsByDate() {
    const groups = new Map();
    this.topics.forEach((topic) => {
      const date = new Date(topic.created_at).toLocaleDateString();
      const group = groups.get(date) || [];
      group.push(topic);
      groups.set(date, group);
    });
    this.groupedTopics = Array.from(groups, ([date, topics]) => ({
      date,
      topics,
    }));
  }

  onLoadHistory(reportId: number) {
    this.isLoading = true;
    this.apiService.apiRequestGetPdf(`reports/get_history/${reportId}`, null, 'arraybuffer')
      .subscribe(
        (response: ArrayBuffer) => {
          const pdfBlob = new Blob([response], { type: 'application/pdf' });
          // Create the URL with viewer parameters to hide UI elements
          const rawUrl = URL.createObjectURL(pdfBlob);
          const urlWithParams = rawUrl + '#toolbar=0&navpanes=0&scrollbar=0';
          this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithParams);
          this.data = this.pdfUrl;
          this.isHistory = true;
          this.isLoading = false;
          this.reportId = reportId;
          this.updateChartData(100);
          this.updateOverallScore();
          this.toggleSideWindow()
        },
        err => {
          console.error('Failed to fetch PDF', err);
          this.isLoading = false;
        }
      );
  }

  hexToPdfBlob(hexResponse: string): Blob {
    try {
      // First, check if the response is already binary data
      // If it's actual hex text, proceed with conversion
      if (/^[0-9a-fA-F\s]+$/.test(hexResponse)) {
        // Remove all whitespace and non-hex characters
        const cleanHex = hexResponse.replace(/[^0-9a-fA-F]/g, '');

        // Convert hex string to byte array
        const byteArray = new Uint8Array(cleanHex.length / 2);
        for (let i = 0; i < cleanHex.length; i += 2) {
          byteArray[i / 2] = parseInt(cleanHex.substring(i, i + 2), 16);
        }

        return new Blob([byteArray], { type: 'application/pdf' });
      } else {
        // If not a hex string, it might be binary data encoded as text
        // Try handling it as base64
        try {
          const binary = atob(hexResponse);
          const bytes = new Uint8Array(binary.length);
          for (let i = 0; i < binary.length; i++) {
            bytes[i] = binary.charCodeAt(i);
          }
          return new Blob([bytes], { type: 'application/pdf' });
        } catch (e) {
          // If not base64, return as-is and hope the browser can interpret it
          return new Blob([hexResponse], { type: 'application/pdf' });
        }
      }
    } catch (e) {
      console.error('Error processing PDF data:', e);
      throw new Error('Failed to process PDF data');
    }
  }

  onSelectProcess(e: any) {
    this.selectedProcess = e.value;
  }

  onSelectComplianceCategory(e: any) {
    this.selectedComplianceCategory = e.value;
  }

  updateOverallScore(): void {
    const finalScore = 90; 
    this.overallscoreoptions = {
      ...this.overallscoreoptions,
      series: [finalScore]
    };
    
    // Update the chart if view is initialized
    if (this.overallscore) {
      this.ngZone.run(() => {
        this.overallscore.updateOptions(this.overallscoreoptions);
        this.cdr.detectChanges();
      });
    }
  }

  updateChartData(progressPercentage: number): void {
    const totalCriteria = this.fullCriteriaData.categories.length;
    const criteriaToShow = Math.ceil((progressPercentage / 100) * totalCriteria);
    
    // Limit to the maximum number of criteria
    const visibleCount = Math.min(criteriaToShow, totalCriteria);
    
    // Create slices of the data arrays
    const visibleCompletionData = this.fullCriteriaData.completion.slice(0, visibleCount);
    const visibleDeviationData = this.fullCriteriaData.deviation.slice(0, visibleCount);
    // const visibleCategories = this.fullCriteriaData.categories.slice(0, visibleCount);
    
    // Update the chart options
    this.criteriascoreoptions = {
      ...this.criteriascoreoptions,
      series: [
        {
          name: "Completion",
          data: visibleCompletionData,
          color: "#21a66a"
        },
        {
          name: "Deviation",
          data: visibleDeviationData,
          color: "#f55142"
        }
      ],
      // xaxis: {
      //   categories: visibleCategories
      // }
    };
    
    // Trigger chart update if the view is initialized
    if (this.criteriascore) {
      this.ngZone.run(() => {
        this.criteriascore.updateOptions(this.criteriascoreoptions);
        this.cdr.detectChanges();
      });
    }
  }

  onGenerate() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }

    // Reset the data
    this.texts = ['Please Wait', 'Analysing Criteria', 'Checking With Aspice Criteria', 'Comparing User Evidences'];
    this.isStreamingInProgress = false;
    this.streamingText = 'Performing Gap Analysis';
    this.startTextAnimation();
    this.isLoading = true;
    this.analysisResults = [];
    this.data = '';
    this.progressPercentage = 1;
    this.reportId = null;
    this.isHistory = false;
    let language = this.isEnglish ? 'ENGLISH' : 'GERMAN'; 
    // Initialize charts with starting values
    this.updateChartData(1);

    let set = {
      file_id: this.fileDetails.map(data => data.document_id),
      process_id: this.selectedProcess,
      compliance_category : this.selectedComplianceCategory,
      language : language,
      user_id: 1,
      project_id: 1,
    };

    // Track what we've already processed for each criterion to prevent duplication
    const processedContentByCriterion = new Map();

    this.apiService.apiRequestEventStreamAnalysis(`generate_gap_analysis/generate_analysis`, '', set).subscribe({
      next: (event) => {
        try {
          let eventData;
          if (typeof event === 'string' && event.startsWith('data:')) {
            // Extract the JSON part after "data: "
            const jsonStr = event.substring(5).trim();
            eventData = JSON.parse(jsonStr);
          } else if (typeof event === 'object') {
            eventData = event.data || event;
          } else {
            console.error('Unrecognized event format:', event);
            return;
          }

          // Handle error events
          if (eventData.type === 'error' || eventData.type === 'connection_error') {
            const errorMessage = eventData.content || 'An unknown error occurred during gap analysis';
            
            // Show error in toaster
            this.toastr.error(errorMessage, 'Gap Analysis Error', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              closeButton: true
            });

            this.isLoading = false;
            this.isStreamingInProgress = false;
            this.isError = true;
            this.ngZone.run(() => {
              this.cdr.detectChanges();
            });

            return; // Stop further processing
          }

          // Handle progress updates
          if (eventData.type === 'progress') {
            if (eventData.content) {
              // Force change detection - run in NgZone to ensure Angular detects changes
              this.ngZone.run(() => {
                this.cdr.detectChanges();
              });
            }
          }
          // Handle progress percentage updates
          else if (eventData.type === 'progress_percentage') {
            this.progressPercentage = parseInt(eventData.content);
            
            // Update charts based on new progress percentage
            this.updateChartData(this.progressPercentage);
            
            // Force change detection
            this.ngZone.run(() => {
              this.cdr.detectChanges();
            });
          }
          // Handle response content updates
          else if (eventData.type === 'response') {
            if (eventData.content) {
              this.isLoading = false;
              this.isStreamingInProgress = true;

              // Store the criterion_id for organizing responses
              const criterionId = eventData.criterion_id || 'unknown';

              // Check if we've seen this exact content for this criterion before
              const existingContent = processedContentByCriterion.get(criterionId) || '';

              // Only process if this is new content
              if (!existingContent.includes(eventData.content)) {
                // Find existing result for this criterion or create a new one
                let criterionResult = this.analysisResults.find(result => result.criterion_id === criterionId);
                if (!criterionResult) {
                  criterionResult = { criterion_id: criterionId, content: '' };
                  this.analysisResults.push(criterionResult);
                }

                // Append the content to the existing result
                criterionResult.content += eventData.content;

                // Update our tracking of processed content
                processedContentByCriterion.set(criterionId, criterionResult.content);

                // Update the main data field ONLY with the new content
                this.data += eventData.content;
              }

              // Force immediate UI update
              this.ngZone.run(() => {
                this.cdr.detectChanges();
              });
            }
          }
          // Handle completion
          else if (eventData.type === 'complete') {
            this.isLoading = false;
            this.isStreamingInProgress = true;
            this.streamingText = 'Gap Analysis Completed';

            // Update charts to show full data on completion
            this.updateChartData(100);
            this.updateOverallScore();

            // Store the report ID in a constant variable
            if (eventData.report_id) {
              this.reportId = eventData.report_id;
            }

            // Force change detection
            this.ngZone.run(() => {
              this.cdr.detectChanges();
            });
          }
        } catch (error) {
          console.error('Error in event processing:', error);
          this.isError = true;
          // Show error in toaster
          this.toastr.error('An unexpected error occurred during processing', 'Gap Analysis Error', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            closeButton: true
          });

          this.isLoading = false;
          this.isStreamingInProgress = false;

          // Force change detection even on error
          this.ngZone.run(() => {
            this.cdr.detectChanges();
          });
        }
      },
      error: (error) => {
        console.error('Failed to fetch reports', error);
        this.isError = true;
        // Show error in toaster
        this.toastr.error('Failed to start gap analysis. Please try again.', 'Connection Error', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true
        });

        this.isLoading = false;
        this.isStreamingInProgress = false;

        // Force change detection
        this.ngZone.run(() => {
          this.cdr.detectChanges();
        });
      },
      complete: () => {
        this.isLoading = false;
        if(this.isError){
          this.progressPercentage = 0;
          this.isStreamingInProgress = false;
        }else{
          this.progressPercentage = 100;
          this.isStreamingInProgress = true;
          this.streamingText = 'Gap Analysis Completed';
        }
        
        this.updateChartData(100);
        this.updateOverallScore();

        // Force change detection
        this.ngZone.run(() => {
          this.cdr.detectChanges();
        });

        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    });
  }

  onClear() {
    this.data = '';
    this.form.reset();
    this.createForm();
    this.fileDetails = [];
    this.initializeCharts();
  }

  startTextAnimation() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }

  onDownloadReport() {
    this.apiService.apiRequestGetBlob(`reports/download-report/${this.reportId}`).subscribe(
      (data: Blob) => {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      },
      error => {
        console.error('Failed to fetch reports', error);
        this.isLoading = false;
      }
    );
  }

  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}