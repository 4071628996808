import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environment/environment';
const BASE_URL = environment.aspiceUrl;

@Injectable({
  providedIn: 'root'
})
export class AspiceApiService {

  constructor(private http: HttpClient) { }


  apiRequestGet(endPoint?: any, queryParam?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.get<any>(`${url}`, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID": authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err))
      })
    );
  }

  apiRequestPost(endPoint?: any, queryParam?: any, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.post<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID": authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err))
      })
    );
  }

  apiRequestPatch(endPoint?: any, queryParam?: any, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.patch<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID": authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      })
    );
  }

  apiRequestPut(endPoint?: any, queryParam?: any, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.put<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID": authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      })
    );
  }


  apiRequestDelete(endPoint?: any, queryParam?: any, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.delete<any>(`${url}`, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID": authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error())
      })
    );
  }

  convertTextToFormData(text: string): FormData {
    const formData = new FormData();
    formData.append('text', text);
    return formData;
  }

  apiRequestPostEventStream(endPoint?: any, queryParam?: any, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return new Observable(observer => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      xhr.setRequestHeader('ngrok-skip-browser-warning', 'truppe');
      xhr.setRequestHeader('X-Refresh-Token', refreshToken);
      xhr.setRequestHeader('X-Access-Token', 'Bearer ' + authorization);
      xhr.setRequestHeader('X-Auth-User-ID', authUserid);

      let buffer = '';

      xhr.onprogress = () => {
        // Get new response data
        const newData = xhr.responseText.substring(buffer.length);
        buffer = xhr.responseText;

        // Split by lines and emit each line
        const lines = newData.split('\n');
        lines.forEach(line => {
          if (line.trim()) {
            observer.next(line);
          }
        });
      };

      xhr.onload = () => {
        observer.complete();
      };

      xhr.onerror = (err) => {
        observer.error(err);
      };

      xhr.send(payload);

      return () => {
        xhr.abort();
      };
    });
  }



  apiRequestEventStreamAnalysis(endPoint?: string, queryParam?: string, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');
  
    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }
  
    return new Observable(observer => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('ngrok-skip-browser-warning', 'truppe');
      xhr.setRequestHeader('X-Refresh-Token', refreshToken);
      xhr.setRequestHeader('X-Access-Token', 'Bearer ' + authorization);
      xhr.setRequestHeader('X-Auth-User-ID', authUserid);
      xhr.setRequestHeader('Accept', 'text/event-stream');
      
      let buffer = '';
      
      // Use a Set to track processed events by their content hash
      const processedEvents = new Set();
  
      // Process chunks as they arrive
      xhr.onprogress = function() {
        try {
          // Get the new data
          const newData = xhr.responseText.substring(buffer.length);
          buffer += newData;
          
          // Try to extract complete events from the buffer
          const eventDelimiter = '\n\n';
          let eventEndIndex;
          
          while ((eventEndIndex = buffer.indexOf(eventDelimiter)) !== -1) {
            const eventData = buffer.substring(0, eventEndIndex);
            buffer = buffer.substring(eventEndIndex + eventDelimiter.length);
            
            // Check if eventData is not empty
            if (eventData.trim()) {
              // Find the data: prefix and extract the content
              const dataPrefix = 'data: ';
              if (eventData.includes(dataPrefix)) {
                const dataLine = eventData.split('\n').find(line => line.startsWith(dataPrefix));
                if (dataLine) {
                  const jsonStr = dataLine.substring(dataPrefix.length).trim();
                  try {
                    const parsedData = JSON.parse(jsonStr);
                    
                    // Create a unique identifier for this event based on type and content
                    let eventIdentifier;
                    if (parsedData.type === 'response' && parsedData.criterion_id && parsedData.content) {
                      // For response events, use criterion_id + content hash
                      eventIdentifier = parsedData.criterion_id + ':' + parsedData.content;
                    } else {
                      // For other events, use the full JSON string
                      eventIdentifier = JSON.stringify(parsedData);
                    }
                    
                    // Only process if we haven't seen this exact event before
                    if (!processedEvents.has(eventIdentifier)) {
                      processedEvents.add(eventIdentifier);
                      observer.next(dataLine); // Send the raw data line to preserve the "data:" prefix
                    }
                  } catch (jsonError) {
                    console.warn('Failed to parse JSON:', jsonStr, jsonError);
                    // Even for unparseable JSON, try to avoid duplicates
                    if (!processedEvents.has(dataLine)) {
                      processedEvents.add(dataLine);
                      observer.next(dataLine);
                    }
                  }
                }
              } else {
                // If no data: prefix, try to parse the event directly
                try {
                  const parsedEvent = parseEventData(eventData);
                  if (parsedEvent) {
                    const eventString = JSON.stringify(parsedEvent);
                    if (!processedEvents.has(eventString)) {
                      processedEvents.add(eventString);
                      observer.next(parsedEvent);
                    }
                  }
                } catch (parseError) {
                  console.warn('Failed to parse event data:', eventData, parseError);
                }
              }
            }
          }
        } catch (error) {
          console.error('Error processing stream data:', error);
          observer.error(new Error('Error processing stream data: ' + error.message));
        }
      };
      
      xhr.onload = function() {
        try {
          // Process any remaining data in the buffer
          if (buffer.trim()) {
            const dataPrefix = 'data: ';
            if (buffer.includes(dataPrefix)) {
              const dataLine = buffer.split('\n').find(line => line.startsWith(dataPrefix));
              if (dataLine && !processedEvents.has(dataLine)) {
                processedEvents.add(dataLine);
                observer.next(dataLine);
              }
            } else {
              const event = parseEventData(buffer);
              if (event) {
                const eventString = JSON.stringify(event);
                if (!processedEvents.has(eventString)) {
                  processedEvents.add(eventString);
                  observer.next(event);
                }
              }
            }
          }
          
          // Send a complete event if not already sent
          const completeEvent = 'data: {"type": "complete", "content": "Stream completed"}';
          if (!processedEvents.has(completeEvent)) {
            observer.next(completeEvent);
          }
          observer.complete();
        } catch (error) {
          observer.error(new Error('Error finalizing stream: ' + error.message));
        }
      };
      
      xhr.onerror = function(error) {
        observer.error(error || new Error('Network error occurred'));
      };
      
      xhr.ontimeout = function() {
        observer.error(new Error('Request timed out'));
      };
      
      // Function to parse event data
      function parseEventData(eventData: string) {
        try {
          const lines = eventData.split('\n');
          const event: any = {};
          
          lines.forEach(line => {
            if (line.startsWith('event: ')) {
              event.type = line.substring(7);
            } else if (line.startsWith('data: ')) {
              const dataContent = line.substring(6).trim();
              try {
                // Handle concatenated JSON objects
                if (dataContent.includes('}{')) {
                  // Split the concatenated JSON objects
                  const jsonObjects = dataContent.match(/\{[^}]*\}/g);
                  if (jsonObjects) {
                    // Combine all response fragments
                    event.data = { response: '' };
                    jsonObjects.forEach(jsonObj => {
                      try {
                        const parsedObj = JSON.parse(jsonObj);
                        if (parsedObj.response) {
                          event.data.response += parsedObj.response;
                        }
                      } catch (e) {
                        console.warn('Failed to parse JSON object:', jsonObj, e);
                      }
                    });
                  }
                } else {
                  // Regular JSON parsing for single objects
                  const cleanedContent = dataContent.replace(/^\uFEFF/, '');
                  event.data = JSON.parse(cleanedContent);
                }
              } catch (e) {
                console.warn('Failed to parse as JSON:', dataContent, e);
                event.data = dataContent;
              }
            }
          });
          
          return Object.keys(event).length > 0 ? event : null;
        } catch (error) {
          console.error('Error parsing event data:', error, eventData);
          return null;
        }
      }
      
      try {
        xhr.send(JSON.stringify(payload));
      } catch (error) {
        observer.error(new Error('Error sending request: ' + error.message));
      }
      
      return () => {
        xhr.abort();
      };
    });
  }
  

  apiRequestGetBlob(endPoint?: any, queryParam?: any): Observable<Blob> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.get(url, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID": authUserid
      }),
      responseType: 'blob'
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err))
      })
    );
  }



  apiRequestGetPdf(endPoint: string, queryParam?: string, responseType: string = 'json') {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid: any = sessionStorage.getItem('authuser');
    
    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }
    
    return this.http.get(url, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID": authUserid
      }),
      responseType: responseType as any
    });
  }

}
