import { Component } from '@angular/core';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';

@Component({
  selector: 'app-tbcm-aspice-dashboard',
  templateUrl: './tbcm-aspice-dashboard.component.html',
  styleUrls: ['./tbcm-aspice-dashboard.component.scss']
})
export class TbcmAspiceDashboardComponent {
  languages = [
    { code: 'en', name: 'English', disabled: false },
    { code: 'de', name: 'German', disabled: true }
  ];

  workflowOptions = [
    {
      id: 1,
      title: 'Independent Evidence Analysis',
      description: 'To analyze and process your evidences and find gaps'
    },
    {
      id: 2,
      title: 'Accelerated ASPICE Assessment',
      description: 'Accelerated ASPICE assessment based on high price gaps found in evidence analysis'
    }
  ];

  availableAgents = [
    {
      id: 1,
      name: 'Evidence Analysis Agent',
      description: 'To analyze and process your evidences and find gaps'
    },
    {
      id: 2,
      name: 'ASPICE Assessment Agent',
      description: 'To conduct an interactive ASPICE assessment with real-time chat'
    }
  ];

  selectedLanguage: string | null = null;
  selectedWorkflowOption: number | null = null;
  selectedAgent: number | null = null; 


  constructor( private ecService : EncryptService){

  }

  onLanguageSelect(languageCode: string) {
    this.selectedLanguage = languageCode;
    this.selectedWorkflowOption = null;
    this.selectedAgent = null;
  }


  onAgentSelect(agentId: number) {
    this.selectedAgent = agentId;
  }

  onWorkflowOptionSelect(optionId: number) {
    this.selectedWorkflowOption = optionId;
  }


  isStartAssessmentEnabled(): boolean {
    return !!(this.selectedLanguage && this.selectedWorkflowOption );
  }

  startAssessment() {
    if (this.isStartAssessmentEnabled()) {
      console.log('Assessment Started', {
        language: this.selectedLanguage,
        workflowOption: this.selectedWorkflowOption,
        agent: this.selectedAgent
      });

      if(this.selectedWorkflowOption === 1){
        const navigationUrl = `features/feature-dashboard?application_id=${encodeURIComponent(this.ecService.encrypt('5'))}`;
        window.location.href = navigationUrl;
      }else{
        const navigationUrl = `features/tbcm-aspice`;
        window.location.href = navigationUrl;
      }
    }
  }
}