<div class="tool-bar">
  <div class="w-50 d-flex align-items-center justify-content-start">
    <form [formGroup]="form">

      <div class="drop-0">

        <div class="settings-ico">
          <div class="icon-btn-change" (click)="toggleLanguage()">
            <ng-container *ngIf="isEnglish">
              <img class="btn-size" src="assets/images/EN.svg" alt="English" />
            </ng-container>
            <ng-container *ngIf="!isEnglish">
              <img class="btn-size" src="assets/images/DE.svg" alt="German" />
            </ng-container>
          </div>
        </div>


        <mat-form-field appearance="outline" class="example-full-width dw">
          <mat-label> Compliance Category</mat-label>
          <mat-select formControlName="compliance_category" id="compliance_category_dropdown"
            name="compliance_category_dropdown" (selectionChange)="onSelectComplianceCategory($event)">
            <mat-option *ngFor="let option of compliceCategory" [value]="option.id"
              [id]="option.id" [disabled]="!option.is_active">
              {{option.compliance_category}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="example-full-width dw">
          <mat-label>Process Selection</mat-label>
          <mat-select formControlName="process" id="process_dropdown" name="process_dropdown"
            (selectionChange)="onSelectProcess($event)">
            <mat-option *ngFor="let option of processOptions" [value]="option.process_id" [id]="option.process_id"
              [disabled]="!option.is_available">
              {{option.process_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field appearance="outline" class="example-full-width dw">
          <mat-label>Assessment Input</mat-label>
          <mat-select formControlName="assessmentInput" id="assessmentInput" name="assessmentInput"
            (selectionChange)="onSelectAssessmentInput($event)">
            <mat-option *ngFor="let option of assessmentInput" [value]="option.id" [id]="option.id"
              [disabled]="!option.is_active">
              {{option.assessment_input}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="dw">
          <input matInput type="number" required formControlName="noOfCriteira" min="1" max="10"
            placeholder="Enter No Of Criterias">
        </mat-form-field>

      </div>
    </form>

  </div>

  <div class="w-50 d-flex align-items-center justify-content-end">
    <button mat-raised-button class="generate-btn" [disabled]="!form.valid" (click)="startAssessment()">
      <span class="gen-text">Start Assessment</span>
    </button>
    <button mat-raised-button class="clear-btn" *ngIf="form.dirty || form.touched || this.messages.length > 0"
      (click)="onClear()">
      <span class="clear-text">Clear</span>
    </button>
  </div>

</div>

<mat-drawer-container class="h-full" autosize>

  <mat-drawer #drawer class="example-sidenav ps-3 pr-0 pb-0 pt-2" mode="side">
    <div class="sidebar-container">

      <!-- Chat History -->
      <div class="history-container">
        <div class="history-title">History</div>



        <div class="chat-list">
          <div class="chat-item" *ngFor="let item of historyData" (click)="loadChat(item)">
            <mat-icon class="chat-icon">chat</mat-icon>
            <div class="chat-content">
              <div class="chat-topic">{{item.compliance_category}}</div>
              <div class="chat-date">{{item.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </mat-drawer>

  <mat-icon class="toggle-btn" (click)="handleToggleSideNav()">
    {{isSideNavOpened ? "chevron_left" : "chevron_right"}}
  </mat-icon>

  <div class="data-container pt-2">
    <div class="welcome-container" *ngIf="messages.length == 0">
      <span class="app-title">Assessment</span>
      <p class="app-subtitle pt-1">
        Conduct an interactive assessment with real-time chat for the selected compliance category
      </p>
    </div>

    <!-- Chat Messages Container -->
    <section class="messages-container" #messageContainer *ngIf="messages.length > 0" (scroll)="onScroll()">

      <!-- Chat Messages Container -->
      <div class="messages-wrapper" #messageContainer *ngIf="messages.length > 0">
        <div class="messages-inner">
          <!-- Message Container -->

          <div class="message-list">
            <div *ngFor="let message of messages" class="message-group">
              <!-- User Message Container -->
              <div class="user-message" *ngIf="message.role === 'user'">
                <!-- User Avatar Container -->
                <div class="avatar-container">
                  <img src="assets/images/user.svg" alt="User Avatar" class="avatar-icon" />
                </div>

                <!-- User Message Content -->
                <div class="message-content">
                  <div class="message-text">{{message.question || message.content}}</div>
                </div>
              </div>

              <!-- Assistant Message Container -->

              <div class="assistant-message" *ngIf="message.role === 'assistant'">
                <!-- Assistant Avatar Container -->
                <div class="avatar-container">
                  <img src="assets/images/Icon_logo.svg" alt="Assistant Avatar" class="avatar-icon" />
                </div>

                <!-- Loading State - ONLY shown when showResult is false -->
                <div *ngIf="!message.showResult" class="mb-4 message-content bg-grey">
                  <!-- First Loading State - Analysing Files -->
                  <div class="d-flex align-items-center gap-2 mb-3">
                    <div class="position-relative" style="width: 1.25rem; height: 1.25rem;">
                      <!-- Bootstrap spinner when in loading state 1 -->
                      <div *ngIf="message.loadingState === 1" class="spinner-border spinner-border-sm text-primary"
                        role="status">
                        <span class="visually-hidden">Analysing...</span>
                      </div>

                      <!-- Tick mark (check icon) when loading state is 2 or higher -->
                      <div *ngIf="message.loadingState >= 2" class="text-primary">
                        <i class="bi bi-check-circle-fill"></i>
                      </div>
                    </div>

                    <!-- Text with conditional styling using ngClass -->
                    <span class="small" [ngClass]="{'text-primary': message.loadingState > 1 && message.displayContent !== 'Failed to generate response. Please try again.', 
                        'text-danger': message.displayContent === 'Failed to generate response. Please try again.', 
                        'text-secondary': message.loadingState === 1}">
                      {{ message.displayContent === 'Failed to generate response. Please try again.'
                      ? 'Failed to generate response'
                      : 'Executing Assessment' }}
                    </span>
                  </div>

                  <!-- Second Loading State - Generating Response -->
                  <div *ngIf="message.loadingState >= 2 && isProcessStarted" class="d-flex align-items-center gap-2">
                    <div class="position-relative" style="width: 1.25rem; height: 1.25rem;">
                      <!-- Bootstrap spinner when in loading state 2 -->
                      <div *ngIf="message.loadingState === 2" class="spinner-border spinner-border-sm text-success"
                        role="status">
                        <span class="visually-hidden">Generating...</span>
                      </div>

                      <!-- Tick mark when loading state is higher than 2 -->
                      <div *ngIf="message.loadingState > 2" class="text-success">
                        <i class="bi bi-check-circle-fill"></i>
                      </div>
                    </div>

                    <!-- Text with conditional styling using ngClass -->
                    <span class="small" [ngClass]="message.loadingState > 2 ? 'text-success' : 'text-secondary'">
                      Evaluating User Response
                    </span>
                  </div>
                </div>

                <!-- Results Section - ONLY shown when showResult is true -->
                <div class="message-content bg-grey" *ngIf="message.showResult">
                  <div class="message-text">
                    <markdown class="markdown" [data]="message.displayContent"></markdown>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>

    <!-- Fixed Input at Bottom -->
    <div class="input-container">
      <div class="input-wrapper">
        <div class="input-box">
          <div class="input-top">
            <svg xmlns="http://www.w3.org/2000/svg" class="input-icon" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>
            <textarea #messageInput rows="1" [(ngModel)]="newMessage" (input)="autoGrow($event)"
              (keydown.enter)="sendMessage(); $event.preventDefault()" placeholder="Please Enter Assessment "
              class="input-textarea"></textarea>
          </div>

          <div class="input-bottom">
            <button (click)="openPopup($event)" class="source-btn" [disabled]="!enableFileUpload">
              <svg xmlns="http://www.w3.org/2000/svg" class="source-icon" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                  d="M16.5 10.5v5a4.5 4.5 0 01-9 0v-7a3 3 0 116 0v6.5a1.5 1.5 0 11-3 0v-6" />
              </svg>
              <span class="source-text" *ngIf="enableFileUpload && !uploadedFile">Select Source</span>
              <span class="source-text" *ngIf="enableFileUpload && uploadedFile">{{uploadedFile}}</span>

              <div *ngIf="isUploading" class="upload-status">
                <svg class="loading-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="loading-circle" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="loading-path" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                <div class="upload-text">Upload in progress...</div>
              </div>
            </button>

            <div class="send-container">
              <button 
              (click)="onDownload()" class="send-btn pr-2" *ngIf="isDownloadEnabled">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="send-icon">
                <rect width="24" height="24" fill="#0a3e70" rx="4" />
                <path d="M12 4v12M8 12l4 4 4-4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 18h12" stroke="white" stroke-width="2" stroke-linecap="round" />
              </svg>
            </button>

              <button
                [disabled]="!newMessage.trim() || !isProcessStarted || isLoadingChat"
                [ngClass]="{'disabled': !newMessage.trim() || !isProcessStarted || isLoadingChat}"
                (click)="sendMessage()" class="send-btn">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="send-icon">
                  <rect x="0" y="0" width="24" height="24" class="send-bg" rx="4" />
                  <g transform="scale(0.8) translate(3, 3)">
                    <path d="M22 2L11 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" fill="none" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-drawer-container>

<div *ngIf="isLoading" class="loader-container">
  <div class="ring-loader">
  </div>
  <div class="loading-text">{{ currentText }}</div>
</div>