import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit{

  isTeraUser : boolean = false;

  ngOnInit(){
    const currentUser: any = window.sessionStorage.getItem('currentUser');
    const user = JSON.parse(currentUser);
    this.isTeraUser = user.user_type == 'TERA' ? true : false;
  }
}
